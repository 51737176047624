import { UserResponse } from "../APIs";
import { AzureFunctionProxy } from "../AzureFunctionDefinition";
import { TokenAuthContext } from "../ConflictsContext";
import { GlobalUserService } from "../GlobalUserService/GlobalUserService";

export function getMockGlobalUserService(context: TokenAuthContext, users: UserResponse[]) {
    const userManagement: {
        getUsers: AzureFunctionProxy<
            {
                tenancyId: string;
            },
            UserResponse[],
            never
        >;
    } = {
        getUsers: async () => {
            return Promise.resolve(users);
        }
    };
    const globalUserService = new GlobalUserService(context, userManagement);
    return globalUserService;
}
