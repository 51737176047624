import { APIs, GlobalUserService, TokenAuthContext } from "aderant-conflicts-common";
import { IDENTITY_CONFLICTS_PRODUCTION_SUFFIX, User } from "aderant-conflicts-models";

export class UserService {
    globalUserService: GlobalUserService;
    constructor(private context: TokenAuthContext) {
        this.globalUserService = new GlobalUserService(context, APIs.getUserManagementProxy(context, context.logger));
    }

    async getUsers(): Promise<User[]> {
        this.context.logger.info(`UserService: Fetching users for tenancy id ${this.context.currentUser.tenancy.id}`);

        const users = await this.globalUserService.getUsers({
            tenancyId: this.context.currentUser.tenancy.id,
            uniqueName: this.context.currentUser.tenancy.uniqueName,
            subscriptionId: this.context.currentUser.tenancy.subscription?.id ?? IDENTITY_CONFLICTS_PRODUCTION_SUFFIX
        });

        if (users.length > 0) {
            this.context.logger.info(
                `UserService: ${users.length} users fetched for tenancy id ${this.context.currentUser.tenancy.id} and subscription id ${
                    this.context.currentUser.tenancy.subscription?.id ?? IDENTITY_CONFLICTS_PRODUCTION_SUFFIX
                }`
            );
        } else {
            this.context.logger.info(
                `UserService: Didn't find any users when fetching user list for tenancy id ${this.context.currentUser.tenancy.id} and subscription id ${
                    this.context.currentUser.tenancy.subscription?.id ?? IDENTITY_CONFLICTS_PRODUCTION_SUFFIX
                }`
            );
        }

        return users;
    }
}
