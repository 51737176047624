import { Forbidden, Message, UnexpectedError, ValidationErrors } from "aderant-conflicts-models";
import { LoginPage as WCLoginPage, aderantColors } from "@aderant/aderant-react-components";
import { ConflictsLogo } from "components/Logo/ConflictsLogo";
import React from "react";

type ComponentProps = {
    onSignIn: (loginId: string) => void;
    lastSignInError?: ValidationErrors | UnexpectedError | Forbidden;
    isAcquiringToken: boolean;
    isAppLoading: boolean;
    setErrorMessage: (message: string) => void;
    subscriptions: string[];
    onSelectSubscription: (subscription: string) => void;
    selectedSubscription: string | undefined;
    isLoggedIn: boolean;
    loginHint?: string;
};

const appDeveloper = "Aderant";
const appName = "Conflicts";

export const LoginMessages = {
    UNEXPECTED_ERROR_USER_TEXT: new Message("UNEXPECTED_ERROR_USER_TEXT", "Error when signing in. Check the browser console for more information."),
    UNAUTHORISED_ERROR_USER_TEXT: new Message("UNAUTHORISED_ERROR_USER_TEXT", `You are not authorized to access ${appDeveloper} ${appName}. Please contact your system administrator.`),
    UNAUTHORISED_SUBSCRIPTION_ERROR_TEXT: new Message<[string]>(
        "UNAUTHORISED_ERROR_SUBSCRIPTION_TEXT",
        `You do not have access to subscription {0}. To continue using Conflicts, open it in a new tab or window.`
    ),
    APP_DESCRIPTION: new Message(
        "APP_DESCRIPTION",
        `Welcome to ${appName}. ${appDeveloper}'s easy-to-use cloud native risk management solution providing comprehensive conflicts review and clearance capabilities.`
    )
};

export default function LoginPage(props: ComponentProps): JSX.Element {
    const getErrorMessage = () => {
        if (!props.lastSignInError) {
            return "";
        }

        switch (props.lastSignInError?._conflictserrortype) {
            case "ACCESS_DENIED": {
                const inx = LoginMessages.UNAUTHORISED_SUBSCRIPTION_ERROR_TEXT.getMessage("DummySubscription").indexOf("DummySubscription");
                if (inx > 1 && props.lastSignInError.message.substring(0, inx - 1) == LoginMessages.UNAUTHORISED_SUBSCRIPTION_ERROR_TEXT.getMessage("").substring(0, inx - 1)) {
                    return props.lastSignInError.message;
                }
                return LoginMessages.UNAUTHORISED_ERROR_USER_TEXT.getMessage();
            }
            default:
                return LoginMessages.UNEXPECTED_ERROR_USER_TEXT.getMessage();
        }
    };

    return (
        <>
            <WCLoginPage
                appLogo={<ConflictsLogo color="newLoginLogoColor" />}
                appName={appName}
                appColor={aderantColors.R2D2}
                appDescription={LoginMessages.APP_DESCRIPTION.getMessage()}
                errorMessage={getErrorMessage()}
                setErrorMessage={props.setErrorMessage}
                isAcquiringToken={props.isAcquiringToken}
                isAppLoading={props.isAppLoading}
                isLoggedIn={props.isLoggedIn}
                onSelectSubscription={props.onSelectSubscription}
                onSignIn={props.onSignIn}
                subscriptions={props.subscriptions}
                selectedSubscription={props.selectedSubscription}
                loginHint={props.loginHint}
            />
        </>
    );
}
