import { AccountInfo } from "@azure/msal-common";
import {
    ConflictsError,
    Forbidden,
    forbidden,
    IDENTITY_CONFLICTS_PRODUCT_NAME,
    IDENTITYDEV_CONFLICTS_PRODUCT_NAME,
    IDENTITY_MAIN_ENVIRONMENT_NAME,
    IDENTITYDEV_MAIN_ENVIRONMENT_NAME,
    isObject,
    isString,
    isStringKeyedRecord,
    ok,
    Result,
    Subscription,
    BasicTenancy,
    LoggedInUserTenancy,
    IDENTITY_CONFLICTS_PRODUCTION_SUFFIX,
    getSubscriptionName,
    getSubscriptionResourceNameSuffix
} from "aderant-conflicts-models";
import decodeJwt from "jwt-decode";
import { conflictsRoles } from "..";

export type ConflictsClaims = {
    userId: string;
    roles: string[];
    tenancy: LoggedInUserTenancy;
    displayName: string;
    email: string;
};

//Actual claim validation here:
function validateInternal(claims: Record<string, unknown>, targetSubscription: string): Result<ConflictsClaims, ClaimValidationError | Forbidden> {
    //validate that the stuff we expect exists and is in the right format.
    const userId = claims["sub"];
    if (typeof userId === undefined || typeof userId !== "string" || userId.length < 1) {
        return claimValidationError("Expected there to be a 'sub' claim on the authentication token containing the user management user object id", "validateKnownClaims");
    }
    const extractedRoles = claims["roles"] ?? []; //treat the scope missing the same as an empty roles array, we'll return a 403 below anyway.
    if (!Array.isArray(extractedRoles)) {
        return claimValidationError("Expected roles claim to be an array", "validateKnownClaims");
    }

    let roles = extractedRoles.filter(isString) ?? [];
    if (!targetSubscription) {
        return claimValidationError("Expected target subscription to not be empty", "validateKnownClaims");
    }

    const authTenancy = getAuthTenancy(claims);
    if (!ok(authTenancy)) {
        return authTenancy;
    }
    let subscription: Subscription | undefined = undefined;
    //return the tenancyName, subscription (product environment) and roles for the target subscription.

    //Check the user is allowed to access the requested target subscription and retrieve their roles for that subscription.
    //Note identity calls a subscription a product environment, so we use that terminology for extracting subscriptions
    //from the auth header, which is provided by identity.
    const productEnvironmentsForTenancy = getProductEnvironments(claims, authTenancy);
    if (!ok(productEnvironmentsForTenancy)) {
        return productEnvironmentsForTenancy;
    }
    //find the targetSubscription in the productEnvironmentsForTenancy
    const productEnvironment = productEnvironmentsForTenancy.find((pe) => pe.uniqueName.toLowerCase() === targetSubscription.toLowerCase());

    if (productEnvironment) {
        //They have access to the target environment: Update the roles for the target environment
        roles = productEnvironment.roles;
    } else {
        //They do not have access to the target environment: Update the roles to empty. Will return forbidden below.
        roles = [];
    }

    if (targetSubscription.toLowerCase() === IDENTITY_MAIN_ENVIRONMENT_NAME.toLowerCase() || targetSubscription.toLowerCase() === IDENTITYDEV_MAIN_ENVIRONMENT_NAME.toLowerCase()) {
        //If called with a targetSubscription that is the main (production) environment,
        //return the targetSubscription as the subscription id, the displayName as "Production" and the resourceNameSuffix as an empty string
        subscription = {
            id: targetSubscription,
            displayName: IDENTITY_CONFLICTS_PRODUCTION_SUFFIX,
            resourceNameSuffix: "" //empty because the resourceName for a Production environmant has no suffix after the tenancy name
        };
    } else {
        //If called with a targetSubscription that is not the main (production) environment,
        //return the targetSubscription as the subscription id, and the displayName and resourceNameSuffix (max 8 chars) as the target environment unique name
        subscription = {
            id: targetSubscription,
            displayName: getSubscriptionName(authTenancy.uniqueName, targetSubscription, "validateInternal"),
            //Cannot be more than 8 characters as it is used in storage account name and resource group name
            resourceNameSuffix: getSubscriptionResourceNameSuffix(authTenancy.uniqueName, targetSubscription, "validateInternal")
        };

        //Update the tenancy name to include the target environment name
        //NB!!! We do this as each environment is deployed as a separate tenancy where the tenancy unique name is the firm SalesForce nmber suffixed with the environment name (all lowercase)
        authTenancy.uniqueName = `${authTenancy.uniqueName}${subscription.resourceNameSuffix}`;
        authTenancy.displayName = `${authTenancy.displayName} ${subscription.displayName}`;
    }
    const tenancy: LoggedInUserTenancy = { ...authTenancy, subscription: subscription };

    //if the user has none of the Conflicts roles in their roles array return a 403 forbidden error
    const hasAnyConflictsRoles = roles.filter((r) => conflictsRoles.find((cr) => cr === r)).length > 0;
    if (!hasAnyConflictsRoles) {
        return forbidden();
    }

    //everything seems to be in order, return the data
    return {
        userId: userId,
        roles: roles,
        tenancy: tenancy,
        ...getNames(claims)
    };
}

//This function uses type assertions because we are parsing a JSON.string, so you only know at runtime what the type is.
//We are asserting that the type is correct before casting it to that type. If the type is not correct then we' return a runtime error.
export function getProductEnvironments(claims: Record<string, unknown>, authTenancy: BasicTenancy): Result<ProductEnvironment[], ClaimValidationError> {
    let productEnvironments: Record<string, unknown> | undefined = undefined;
    const conflictsEnvironments: ProductEnvironment[] | undefined = [];
    if (!claims["productenvironments"]) {
        return [];
    }
    if (!isString(claims["productenvironments"])) {
        return claimValidationError("Expected productenvironments claim to be a string", "validateKnownClaims");
    }
    try {
        productEnvironments = JSON.parse(claims["productenvironments"]);
    } catch {
        return claimValidationError("Expected productenvironments claim to be a valid JSON string", "validateKnownClaims");
    }
    if (productEnvironments === undefined) {
        return [];
    }
    if (typeof productEnvironments !== "object") {
        return claimValidationError(`Expected productenvironments claim to JSON.parse to an object`, "validateKnownClaims");
    }
    if (!productEnvironments[authTenancy.uniqueName]) {
        return claimValidationError(`Expected productenvironments to have a '${authTenancy.uniqueName}' property`, "validateKnownClaims");
    }
    const productEnvironmentsForTenancy = productEnvironments[authTenancy.uniqueName];
    if (!isObject(productEnvironmentsForTenancy)) {
        return claimValidationError(`Expected productenvironments.${authTenancy.uniqueName} to be an object`, "validateKnownClaims");
    }
    for (const key in productEnvironmentsForTenancy) {
        const keyParts = key.split(".");
        if (keyParts.length !== 2) {
            return claimValidationError(`Expected productenvironments.${authTenancy.uniqueName}.${key} to be in the form 'product.environment'`, "validateKnownClaims");
        }
        // skip loop if the property is from prototype
        // Next line commented out as app won't build with reference to hasOwnPropertye:
        // if (!productEnvironmentsForTenancyAsObject.hasOwnProperty(key)) continue;
        // skip products that are not the Conflicts or ConflictsDev product
        if (keyParts[0] !== IDENTITY_CONFLICTS_PRODUCT_NAME && keyParts[0] !== IDENTITYDEV_CONFLICTS_PRODUCT_NAME) continue;
        const extractedProductEnvironment = productEnvironmentsForTenancy[key];
        //Expect the object for the product to be an array of strings, which represent the roles the user has for this product/environment combination
        if (!Array.isArray(extractedProductEnvironment)) {
            return claimValidationError(`Expected productenvironments.${authTenancy.uniqueName}.${key} to be an array`, "validateKnownClaims");
        }
        const roles = extractedProductEnvironment.filter(isString);
        if (extractedProductEnvironment.length != roles.length) {
            return claimValidationError(`Expected productenvironments.${authTenancy.uniqueName}.${key} to be an array of strings`, "validateKnownClaims");
        }
        const environmentName = keyParts[1];
        conflictsEnvironments.push({
            uniqueName: key,
            displayName: environmentName,
            roles: roles
        });
    }
    return conflictsEnvironments;
}

function getNames(claims: Record<string, unknown>): { displayName: string; email: string } {
    //none of the stuff in here is 'required' so rather than failing if we can't find it we'll just do a best effort attempt at getting a name, and fall back to email if it's not there.
    const name = getIfString(claims["name"]);
    const given_name = getIfString(claims["given_name"]);
    const family_name = getIfString(claims["family_name"]);
    const email = getIfString(claims["email"]);

    const displayName = name ? name : given_name && family_name ? `${given_name} ${family_name}` : email;
    return { displayName: displayName ?? "", email: email ?? "" };
}

function getIfString(value: unknown): string | undefined {
    if (typeof value === "string") {
        return value;
    } else {
        return undefined;
    }
}

//Entry points for different formats (AccountInfo with idTokenClaims vs an authHeader) here:

/**
 * Validated and extracts all expected claims from a currently logged in account.
 * @param account The logged in account to extract claims from.
 * @returns Validated ConflictsClaims object.
 */
export function validateKnownClaimsFromLoggedInAccount(account: AccountInfo, targetSubscription: string): Result<ConflictsClaims, ClaimValidationError | Forbidden> {
    const idTokenClaims = account.idTokenClaims;
    if (!isStringKeyedRecord(idTokenClaims)) {
        return claimValidationError("Expected account.idTokenClaims to be a string keyed object.", "validateKnownClaimsFromLoggedInAccount");
    }
    return validateInternal(idTokenClaims, targetSubscription);
}

/**
 * Validates and extracts all expected claims from an authorization header from a successful request to a Conflicts function app.
 * **Important**: These claims should only be relied on to be correct if the authorization header is from inside a function app call that was successful - i.e. the token has already been verified by Azure.
 * @param authHeader the 'authorization' header from a successful request to a function app.
 * @returns Validated ConflictsClaims object.
 */
export function validateKnownClaimsFromAuthHeader(authHeader: string, targetSubscription: string): Result<ConflictsClaims, ClaimValidationError | Forbidden> {
    if (!authHeader.startsWith("Bearer ")) {
        return claimValidationError("Expected authHeader to be in standard authorization header format (should be in the form 'Bearer {token}')", "validateKnownClaimsFromAuthHeader");
    }
    const toDecode = authHeader.replace("Bearer", "").trim();

    let decoded;
    try {
        decoded = decodeJwt(toDecode);
    } catch (e) {
        return claimValidationError(
            e,
            "Unexpected error decoding JWT inside validateKnownClaimsFromAuthHeader (don't try to use from auth tokens that haven't already been successfully validated by Azure)."
        );
    }
    const jwt = decoded;
    if (!isStringKeyedRecord(jwt)) {
        return claimValidationError("Expected decoded bearer token to be a string keyed object.", "validateKnownClaimsFromLoggedInAccount");
    }
    return validateInternal(jwt, targetSubscription);
}

//Identifies a product environment (subscription) and the roles that the user has in that environment
export type ProductEnvironment = {
    uniqueName: string;
    displayName: string;
    roles: string[];
};

export interface ClaimValidationError extends ConflictsError {
    _conflictserrortype: "CLAIM_VALIDATION";
    httpStatusCode: 400;
    cause: any;
    context: string;
}

export function claimValidationError(cause: any, context: string): ClaimValidationError {
    return {
        _conflictserrortype: "CLAIM_VALIDATION",
        httpStatusCode: 400,
        cause: cause,
        context: context,
        message: context
    };
}

export function getAuthTenancy(claims: Record<string, unknown>): Result<BasicTenancy, ClaimValidationError> {
    const groups = claims["groups"];
    if (typeof groups === undefined || !Array.isArray(groups) || groups.length !== 1 || typeof groups[0] !== "string" || groups[0].length === 0) {
        return claimValidationError(
            "Expected there to be a 'groups' claim on the logged in account that is an array with a single non empty string in it (the unique tenancy name)",
            "validateKnownClaims"
        );
    }

    const groupIds = claims["groupids"];
    if (typeof groupIds === undefined || !Array.isArray(groupIds) || groupIds.length !== 1 || typeof groupIds[0] !== "string" || groupIds[0].length === 0) {
        return claimValidationError("Expected there to be a 'groupIds' claim on the logged in account that is an array with a single non empty string in it (the tenancy id)", "validateKnownClaims");
    }
    //Note: when this code gets changed to handle situations with multiple groups (tenants), make sure the groups claim items and the groupids claim items are tied together.
    //These two arrays should be ordered the same, and have the same number of items.
    const authTenancy = {
        uniqueName: groups[0].toLowerCase() /*uniqueness of this is case insensitive, toLower it here to make sure our end stays consistent*/,
        displayName: groups[0],
        id: groupIds[0]
    };

    return authTenancy;
}
