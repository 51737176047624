import { Context } from "@azure/functions";
import { ConflictsError, Forbidden, HardcodedPermissionsConnector, isString, LoggedInUser, ok, unexpectedError } from "aderant-conflicts-models";
import { AzureFunctionLogger, AppInsightsClient } from "aderant-web-fw-azfunctions";
import { AxiosResponse } from "axios";
import { AzureFunctionProxy, getAllConflictsRoleScopes, rolesArrayToUserRole, sendRequest, validateKnownClaimsFromAuthHeader } from "../..";
import { ClientAppContext, FunctionAppContext } from "../ConflictsContext";
import { HttpBody, SuccessResponse } from "../Http/HttpResponse";
import { httpResponseFromError } from "./shared/errorHandling";
import { processOrThrowErrorResponse } from "./shared/proxy/processOrThrowErrorResponse";
import { addContentTypeHeader, callImplementationAndHandleHttpResult } from "./shared/server/callImplementationAndHandleHttpResult";
import { buildDependencies, Unbuilt } from "./shared/server/FunctionAppDependency";
import { HttpRequestOptions, HttpQueryParams } from "./shared/proxy/sendRequest";
import { HttpVerb } from "../Http/HttpVerb";
import { Logger } from "aderant-web-fw-core";
import { extractSearchLogContext } from "./shared/server/extractSearchLogContext";
import { getSubscriptionFromUrlQueryParams, getUrlFriendlySubscriptionFromUserContext } from "../../OAuth/Subscription";

export class AzureFunctionDefinition<In extends HttpBody, Out extends HttpBody, Success extends SuccessResponse<Out>, Err extends ConflictsError, QueryParams extends HttpQueryParams = never> {
    public readonly httpVerb: HttpVerb;
    private readonly getUrlEnd: (input: In) => string;
    private readonly expectedErrorTypes: Set<Err["_conflictserrortype"]>;
    constructor(input: {
        getUrlEnd: (input: In) => string;
        httpVerb: HttpVerb;
        /**
         * ```Err["_conflictserrortype"]``` means 'the type of the field _conflictserrortype on type Err'.
         * This will resolve to a Union of _conflictserrortype strings when ```Err``` is a Union of ```ConflictsError``` implementations.
         *
         * Unfortunately we need this *as well as* the ```Err extends ConflictsError``` generic param as you cannot get a list/tuple of strings out of a string union type.
         * It will still be constrained to just values that satisfy ```Err``` however, so potential mistakes are limited.
         */
        expectedErrors: Err["_conflictserrortype"][];
    }) {
        this.httpVerb = input.httpVerb;
        this.getUrlEnd = input.getUrlEnd;
        this.expectedErrorTypes = new Set<Err["_conflictserrortype"]>(input.expectedErrors);
    }

    /**
     * Function to wrap a business logic implementation with necessary Azure Functions result handling, instrumentation etc
     * @param azureContext Azure context from your Azure Function entry point
     * @param internalImpl ```(context: ConflictsContext, input: In, ...dependencies: Deps) => Promise<Success | Err>``` - the implementation of your business logic.
     * @param logContext Properties to include with each log statement.  TenancyName is added automatically
     * @returns ```(input: In, ...dependencies: Deps) => Promise<void>``` - this function should be called in your Azure Function entry point, handles setting Azure context response, instrumentation etc for you.
     */
    public getImplementation<Deps extends Array<unknown>>(
        azureContext: Context,
        appInsightsClient: AppInsightsClient,
        internalImpl: (context: FunctionAppContext, input: In, ...dependencies: Deps) => Promise<Success | Err>,
        logContext?: Record<string, string>
    ): (input: In, ...dependencies: Unbuilt<Deps, FunctionAppContext>) => Promise<void> {
        const logger = new AzureFunctionLogger(azureContext.log, appInsightsClient);
        logContext = logContext || {};
        logger.setLogProperties(logContext); //Set initial properties, tenancyName comes after we parse it
        logger.debug("Running initial context setup (AzureFunctionDefinition.getImplementation).");

        const headers = azureContext.req?.headers;
        if (headers === undefined) {
            //it's safe to just blow up with an unexpectedError here, because we shouldn't be able to get to this point without any request header - Azure would have responded with a error before this point.
            throw unexpectedError("No header found in Azure function request.", "AzureFunctionDefinition.getImplementation");
        }
        const authHeader = azureContext.req?.headers["authorization"];
        if (authHeader === undefined) {
            //it's safe to just blow up with an unexpectedError here, because we shouldn't be able to get to this point without an authorization header - Azure would have responded with a 401 before this point.
            throw unexpectedError("No authorization header found in Azure function request.", "AzureFunctionDefinition.getImplementation");
        }
        const subscription = getSubscriptionFromUrlQueryParams(azureContext.req?.query);
        if (!subscription) {
            //it's safe to just blow up with an unexpectedError here, because we shouldn't be calling FunctionApps without specifying which subscription in the url params.
            throw unexpectedError("No query parameters found in Azure function request. Expected subscription to be in query parameters.", "AzureFunctionDefinition.getImplementation");
        }

        const validatedClaims = validateKnownClaimsFromAuthHeader(authHeader, subscription);
        if (!ok(validatedClaims)) {
            switch (validatedClaims._conflictserrortype) {
                case "CLAIM_VALIDATION": {
                    //It's also safe to blow up with an unexpectedError here, as we know this must at least be a properly signed token from UserManagement to have gotten this far.
                    //If you're getting an error here it's likely that there is something wrong with the formatting/inclusion of expected claims in access tokens received from User Management.
                    throw unexpectedError(
                        validatedClaims,
                        "Error encountered when validating token claims inside AzureFunctionDefinition.getImplementation - check that auth tokens have all expected claims for users."
                    );
                }
                case "ACCESS_DENIED": {
                    //we know from the passed in token that the user is forbidden from accessing Conflicts, so return a short circuit implementation that always sets result to forbidden.
                    //we still return a function conforming to the same signature as the happy path so that callers don't need to write any extra handling for forbidden errors.
                    const forbidden: Forbidden = validatedClaims;
                    //EARLY RETURN
                    return async () => {
                        azureContext.res = addContentTypeHeader(httpResponseFromError(forbidden));
                        return;
                    };
                }
            }
        }

        const userRole = rolesArrayToUserRole(validatedClaims.roles);
        const userContext: LoggedInUser = {
            id: validatedClaims.userId,
            name: validatedClaims.displayName,
            email: validatedClaims.email,
            role: userRole,
            tenancy: validatedClaims.tenancy
        };

        if (userContext.tenancy.uniqueName === "") {
            throw "No tenancy details found for user.";
        }

        logger.setLogProperty("tenancy", userContext.tenancy.uniqueName);

        const context: FunctionAppContext = new FunctionAppContext(new HardcodedPermissionsConnector(), logger, userContext, azureContext, appInsightsClient);

        return async (input: In, ...dependencies: Unbuilt<Deps, FunctionAppContext>) => {
            logger.setLogProperties(extractSearchLogContext(input));
            return await callImplementationAndHandleHttpResult(context.azureContext, context.logger, async () => internalImpl(context, input, ...(await buildDependencies(dependencies, context))));
        };
    }

    /**This will return a proxy function for calling this Azure Function App.
     *
     * On error handling: this will return Err as part of the regular response type of the function.
     * Anything with a non 2XX response will first be compared with the expectedErrors of the function and returned.
     * if one of those. If not it will be wrapped in an UnexpectedError with context info (currently the context is just the url of this azure function).
     *
     * You should rely on this context wrapping - don't write manual error handling just to do more logging/context for unexpected errors - it's probably not neccessary.
     * BOILERPLATE BAD, AUTOMATIC INSTRUMENTATION GOOD
     
     * @param baseUrl this should be the base url of the function app with none of the function specific stuff on the end (i.e. up to the .com and no more)
     * @returns an ```(input: In) => Promise<Result<Out, Err>>)``` (the AzureFunctionProxy type alias mostly just exists because this might eventually have some extra context fields on it)
     */
    public getProxy(baseUrl: URL, authToken: string, logger: Logger): AzureFunctionProxy<In, Out, Err, QueryParams>;
    public getProxy(
        baseUrl: URL,
        context:
            | FunctionAppContext
            | ClientAppContext /**need to be in a situation where you already have an auth token to call another auth token function, so no calling from KeyAuth/QueueTrigger functions */,
        logger?: Logger
    ): AzureFunctionProxy<In, Out, Err, QueryParams>;
    public getProxy(baseUrl: URL, auth: FunctionAppContext | ClientAppContext | string, logger: Logger): AzureFunctionProxy<In, Out, Err, QueryParams> {
        return async (input: In, requestOptions: HttpRequestOptions = {}, queryParams?: QueryParams) => {
            const fullUrl: URL = new URL(this.getUrlEnd(input), baseUrl);
            try {
                const authHeaders = typeof auth === "string" ? this.getTokenAuthHeaders(auth) : await this.getAuthHeaders(auth);
                const subscriptionUrlParam = isString(auth) ? undefined : getUrlFriendlySubscriptionFromUserContext(auth);
                let fullQueryParams: HttpQueryParams = queryParams ?? {};
                if (subscriptionUrlParam) {
                    fullQueryParams = { ...queryParams, subscription: subscriptionUrlParam };
                }
                const response: AxiosResponse<Out> = await sendRequest(
                    typeof auth === "string" ? logger : auth.logger,
                    { url: fullUrl.toString(), httpVerb: this.httpVerb, headers: authHeaders, body: input, params: fullQueryParams },
                    requestOptions,
                    { transformBusinesssDateToDateObject: typeof auth !== "string" && auth.environmentType === "ClientApp" }
                );
                return response.data;
            } catch (err: unknown) {
                return processOrThrowErrorResponse(err, this.expectedErrorTypes, `${this.httpVerb} to ${fullUrl.toString()}`);
            }
        };
    }

    private getTokenAuthHeaders(tokenString: string): AuthHeaders {
        return { authorization: `Bearer ${tokenString}` };
    }

    private async getAuthHeaders(context: ClientAppContext | FunctionAppContext): Promise<AuthHeaders> {
        switch (context.environmentType) {
            case "ClientApp": {
                const token = await context.acquireToken(getAllConflictsRoleScopes());
                return { authorization: `Bearer ${token.accessToken}` };
            }
            case "AzureFunctionApp": {
                // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const auth = context.azureContext.req?.headers as AuthHeaders;
                if (!auth) {
                    throw Error("Couldn't find auth token in request header.");
                }
                return { authorization: auth.authorization };
            }
        }
    }
}

type AuthHeaders = { authorization: string };
