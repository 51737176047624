import { ConflictsError, LoggedInUser, LoggedInUserTenancy, Result, unexpectedError, ValidationMessage } from "aderant-conflicts-models";
import { GlobalUserService } from "./GlobalUserService";
export interface UserNotConflictsUserError extends ValidationMessage, ConflictsError {
    _conflictserrortype: "USER_NOT_CONFLICTS_USER";
    httpStatusCode: 400;
}
function userNotConflictsUserError(userParameterName: string): UserNotConflictsUserError {
    return {
        _conflictserrortype: "USER_NOT_CONFLICTS_USER",
        httpStatusCode: 400,
        message: `The passed in ${userParameterName} does not map to any conflicts users.`,
        messageCode: "VLD_USER_NOT_CONFLICTS_USER"
    };
}

//TODO: we need to handle this case better than just failing - need to work out if we can just
//make this impossible at the UM level, or if we need to work out some way for the caller to specify
//more info.
export interface UserMultipleConflictsUsers extends ValidationMessage, ConflictsError {
    _conflictserrortype: "USER_MULTIPLE_CONFLICTS_USERS";
    httpStatusCode: 400;
}
function userMultipleConflictsUsers(userParameterName: string): UserMultipleConflictsUsers {
    return {
        _conflictserrortype: "USER_MULTIPLE_CONFLICTS_USERS",
        httpStatusCode: 400,
        message: `The passed in ${userParameterName} maps to multiple conflicts users.`,
        messageCode: "VLD_USER_MULTIPLE_CONFLICTS_USERS"
    };
}

export async function getUser(
    userService: GlobalUserService,
    tenancyInfo: LoggedInUserTenancy,
    userEmail: string,
    userParameterName: string
): Promise<Result<LoggedInUser, UserMultipleConflictsUsers | UserNotConflictsUserError>> {
    if (!tenancyInfo.id || !tenancyInfo.subscription?.id) {
        throw unexpectedError(`Tenancy ID '${tenancyInfo.id}' and subscription ID '${tenancyInfo.subscription}' are required to get a user.`, "GlobalUserService.getUser");
    }
    const allUsers = await userService.getUsers({
        tenancyId: tenancyInfo.id,
        uniqueName: tenancyInfo.uniqueName,
        subscriptionId: tenancyInfo.subscription.id
    });
    const users = allUsers.filter((u) => u.email.toLowerCase() === userEmail.toLowerCase());
    if (users.length === 0) {
        return userNotConflictsUserError(userParameterName);
    }
    if (users.length > 1) {
        return userMultipleConflictsUsers(userParameterName);
    }
    return users[0];
}
