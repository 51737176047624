import { AllLookups, DefaultHitResultGridColumnConfigurations, GridConfiguration, LoggedInUser, PermissionsContextDirect, User } from "aderant-conflicts-models";
import { DataGridPreference } from "@aderant/aderant-react-components";
import { ConsoleLogger } from "@aderant/aderant-web-fw-applications";
import { assertUnreachable } from "aderant-web-fw-core";
import _ from "lodash";
import * as MyTypes from "MyTypes";
import { appActionTypes } from "state/actions";
import { ConflictsSagaContext } from "state/store/ConflictsSagaContext";
import { createUninitializedPermissionsContext } from "../store/UninitializedPermissionsContext";

export interface AppState {
    conflictsSagaContext?: ConflictsSagaContext;
    permissionsContext: PermissionsContextDirect;
    /**
     * @deprecated Use permissionsContext.currentUserId
     */
    currentUserId?: string;
    /**
     * @deprecated Use permissionsContext for user permissions related things
     */
    loggedInUser?: LoggedInUser;
    users?: User[];
    isErrorDialogOpen: boolean;
    errorMessage: string;
    lookups: AllLookups;
    grids: {
        [id: string]: {
            //This only holds preferences for the current subscription
            preferences?: DataGridPreference;
            configuration?: GridConfiguration;
        };
    };
    //(FEATURE-FLAG: QUICKSEARCH)
    isQuickSearchEnabled?: boolean;
}

export const GridIds = {
    resultsPage: "results-page-grid",
    quickSearchesPage: "quick-search-page-grid",
    searchesPage: "search-page-grid",
    termsGrid: "terms-grid",
    auditGrid: "audit-grid",
    synonymsGrid: "synonyms-grid"
} as const;

export const initialState: AppState = {
    conflictsSagaContext: undefined,
    permissionsContext: createUninitializedPermissionsContext(new ConsoleLogger()), //this will be replaced in App.tsx on user login, we just have this placeholder that returns false to everything so pages don't have to do the same logic inline everywhere.
    currentUserId: undefined,
    loggedInUser: undefined,
    isErrorDialogOpen: false,
    users: undefined,
    errorMessage: "",
    lookups: { affiliationList: [], partyStatusList: [] },
    grids: {
        [GridIds.resultsPage]: {
            configuration: { configurations: DefaultHitResultGridColumnConfigurations }
        }
    }
};

export const appReducer = (state: AppState = initialState, action: MyTypes.RootAppState): AppState => {
    switch (action.type) {
        case appActionTypes.LOGIN: {
            return {
                ...state,
                currentUserId: action.payload
            };
        }
        case appActionTypes.INITIALIZE_CONTEXT: {
            return {
                ...state,
                conflictsSagaContext: action.payload.conflictsSagaContext,
                loggedInUser: action.payload.loggedInUser,
                permissionsContext: action.payload.permissionsContext
            };
        }
        case appActionTypes.ACTION_FAILURE:
        case appActionTypes.SHOWERROR: {
            return {
                ...state,
                errorMessage: action.payload,
                isErrorDialogOpen: true
            };
        }
        case appActionTypes.HIDEERROR: {
            return {
                ...state,
                isErrorDialogOpen: false
            };
        }
        case appActionTypes.FETCH_USERS_SUCCESS: {
            return {
                ...state,
                users: action.payload
            };
        }
        case appActionTypes.FETCH_LOOKUPS_SUCCESS: {
            return {
                ...state,
                lookups: action.payload
            };
        }
        case appActionTypes.FETCH_HIT_RESULT_GRID_CONFIGURATION_SUCCESS: {
            console.debug("%s: Setting grid definition in state.grids to %s", new Date().toISOString(), action.payload ? "a value" : "undefined");
            return {
                ...state,
                grids: {
                    ...state.grids,
                    [GridIds.resultsPage]: {
                        ...state.grids[GridIds.resultsPage],
                        configuration: action.payload
                    }
                }
            };
        }
        case appActionTypes.FETCH_GRID_PREFERENCES_SUCCESS: {
            const updatedGridState = _.cloneDeep(state.grids);
            Object.keys(action.payload).forEach((gridId: string) => {
                if (updatedGridState[gridId]) {
                    updatedGridState[gridId].preferences = action.payload[gridId];
                } else {
                    updatedGridState[gridId] = {
                        preferences: action.payload[gridId]
                    };
                }
            });
            return {
                ...state,
                grids: updatedGridState
            };
        }
        case appActionTypes.UPDATE_GRID_PREFERENCE_SUCCESS: {
            return {
                ...state,
                grids: {
                    ...state.grids,
                    [action.payload.id]: {
                        ...state.grids[action.payload.id],
                        preferences: action.payload
                    }
                }
            };
        }
        case appActionTypes.FETCH_LOOKUPS:
        case appActionTypes.FETCH_USERS:
        case appActionTypes.SET_USERS:
        case appActionTypes.FETCH_HIT_RESULT_GRID_CONFIGURATION:
        case appActionTypes.FETCH_GRID_PREFERENCES:
        case appActionTypes.UPDATE_GRID_PREFERENCE:
            return state;
    }
    /**sometimes redux will throw internal actions at reducers (e.g. @@redux/INIT), and it expects you to send back the state unchanged
    /*so our "exhaustive" switch statement isn't actually exhaustive. We want to return the current state *at runtime* if we get an unknown action
    /*to conform with redux expectations/best practices, but it's nice to get a compile time error if we haven't explicitly handled any of our own actions
    /*
    /*the wacky "if undefined || not undefined" statement is because just having a non conditional return with a line after it is not valid code. 
    */
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    if (typeof (action as any).type === "undefined" || typeof (action as any).type !== "undefined") {
        return state;
    }
    return assertUnreachable(action);
};
