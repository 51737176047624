import * as _ from "lodash";
import {
    Hit,
    HitComment,
    HitCommentAudit,
    HitStatuses,
    IDENTITY_CONFLICTS_PRODUCTION_SUFFIX,
    IDENTITY_MAIN_ENVIRONMENT_NAME,
    IDENTITYDEV_MAIN_ENVIRONMENT_NAME,
    LoggedInUser,
    Subscription,
    UserRole
} from "..";
import { HitResultDocument } from "../Hit";
import { isVersionSearched, makeEditable, RequestTerm, SearchPersistedInCosmos, SearchVersion, SearchVersionEdited, SearchVersionNew, SearchVersionUnedited } from "../SearchVersion";
import { v4 as uuid } from "uuid";
export function getExistingWithEditsTestSearch(searchVersion: Partial<SearchVersion>): SearchVersionEdited {
    return makeEditable(getTestSearch(searchVersion));
}

export function getExistingWithNoEditsTestSearch(searchVersion: Partial<SearchVersion>): SearchVersionUnedited {
    return getTestSearch({
        ...searchVersion,
        editState: "CURRENT"
    }) as SearchVersionUnedited;
}

function getTestSearch(searchVersion: Partial<SearchVersion>): SearchVersion {
    let testSearchVersion: SearchVersion | SearchVersionNew = {
        ...searchVersion,
        id: searchVersion.id ?? "12345678-1234-5678-90ab-aaaabbbbcccc",
        searchId: searchVersion.searchId ?? "cbb1f894-0e8a-11ec-82a8-0242ac130003",
        name: searchVersion.name || "",
        description: searchVersion.description || "",
        createdByUserId: searchVersion.createdByUserId || "12345678-1234-5678-90ab-aaaabbbbcccc",
        requestedByUserId: searchVersion.requestedByUserId || "12345678-1234-5678-90ab-aaaabbbbcccc",
        assignedToUserId: searchVersion.assignedToUserId,
        searchDate: searchVersion.searchDate || new Date(),
        requestTerms: searchVersion.requestTerms || [],
        number: searchVersion.number || "0",
        version: searchVersion.version || 0,
        status: searchVersion.status || "DRAFT",
        editState: searchVersion.editState || "UNSAVED",
        lastModified: searchVersion.lastModified || new Date(),
        searchDocumentType: "SearchVersion",
        isLatestVersion: searchVersion.isLatestVersion === undefined ? true : searchVersion.isLatestVersion,
        applyFuzzySearch: searchVersion.applyFuzzySearch || false,
        _etag: searchVersion._etag || "ffr1f894-0e8a-11ec-82a8-0242ac130003",
        createdOn: searchVersion.createdOn || new Date(),
        isQuickSearch: false
    };

    if (isVersionSearched(testSearchVersion)) {
        const hitCountByStatus: { [id: string]: number } = {};
        Object.values(HitStatuses).forEach((status: string) => {
            hitCountByStatus[status] = 0;
        });
        testSearchVersion.requestTerms.forEach((term: RequestTerm) => {
            const hits = term.hits;
            if (!hits) {
                return;
            }
            const statusCounts = _.countBy(hits.map((hit: Hit) => hit.status));
            Object.keys(statusCounts).forEach((status: string) => {
                hitCountByStatus[status] += statusCounts[status];
            });
        });

        testSearchVersion = {
            summary: {
                hitCountByStatus: hitCountByStatus
            },
            ...testSearchVersion
        }; //normally this would be a nono - it's readonly for a reason, but this is test code and we know we've just calculated summary
    }

    return testSearchVersion as any;
}

export function getCosmosPersistedTestSearch(searchVersion: Partial<SearchPersistedInCosmos>): SearchPersistedInCosmos {
    return {
        ...getExistingWithNoEditsTestSearch(searchVersion),
        _ts: searchVersion._ts || 100000000
    };
}

export function getNewTestSearch(searchVersion: Partial<SearchVersionNew>): SearchVersionNew {
    const existingWithEdits = getExistingWithEditsTestSearch({ ...searchVersion, editState: "UNSAVED" });
    return { ...existingWithEdits, lastModified: undefined, status: "DRAFT", editState: "NEW", number: undefined, isQuickSearch: false };
}

export function getTestRequestTerm(term: Partial<RequestTerm>): RequestTerm {
    return {
        ...term,
        id: term.id as string,
        term: term.term || "test",
        hits: term.hits,
        searchTerms: term.searchTerms || [],
        affiliation: term.affiliation,
        partyStatus: term.partyStatus
    };
}

export function getTestHit(hit: Partial<Hit>): Hit {
    return {
        ...hit,
        id: Math.floor(Math.random() * 1000000 + 1),
        sourceType: hit.sourceType || getRandomSourceType(),
        sourceData: hit.sourceData || {},
        hitLocations: hit.hitLocations || [],
        status: hit.status || HitStatuses.Unactioned,
        hitOwnerId: hit.hitOwnerId || undefined
    };
}

export function getTestHitResultDocument(hitResult: Partial<HitResultDocument>): HitResultDocument {
    return {
        ...hitResult,
        id: hitResult.id || Math.floor(Math.random() * 1000000 + 1).toString(),
        versionId: hitResult.versionId || Math.floor(Math.random() * 1000000 + 1).toString(),
        requestTermId: hitResult.requestTermId || Math.floor(Math.random() * 1000000 + 1).toString(),
        searchDocumentType: "HitResult",
        hits: hitResult.hits || [],
        ttl: -1
    };
}
function getRandomSourceType(): string {
    const index = Math.floor(Math.random() * 5 + 1 - 1); //0 to 4
    const sourceTypes = ["matter", "client", "vendor", "clientparty", "matterparty"];
    return sourceTypes[index];
}

function getRandomSourceId(): string {
    return Math.max(Math.floor(Math.random() * 120000), 1).toString();
}

export function getHits(hits: Partial<Hit>[], term: string, numGeneratedHits?: number): Hit[] {
    const generatedHits: Hit[] = [];
    const numHits = numGeneratedHits != undefined && numGeneratedHits >= 0 ? numGeneratedHits : Math.max(1, Math.random() * 10 + 1);
    for (let i = 0; i < numHits; i++) {
        const hit = hits.length === 0 ? {} : i > hits.length ? hits[hits.length] : hits[i];
        generatedHits.push({
            id: i,
            sourceType: hit.sourceType ?? getRandomSourceType(),
            sourceData: { id: getRandomSourceId() },
            hitLocations: [{ term: term, location: "", highlights: [] }],
            status: hit.status ?? HitStatuses.Unactioned,
            hitOwnerId: hit.hitOwnerId ?? null
        });
    }
    return generatedHits;
}

export function getRequestTerm(requestTerm: Partial<RequestTerm>, generateHits?: boolean, numGeneratedHits?: number): RequestTerm {
    let generatedHits: Hit[] | null = null;
    if (generateHits && requestTerm.searchTerms && requestTerm.searchTerms.length > 0) {
        generatedHits = [];
        requestTerm.searchTerms.forEach((term) => {
            generatedHits?.push(...getHits([], term, numGeneratedHits));
        });
    }

    return {
        id: requestTerm.id as string,
        term: requestTerm.term || "",
        hits: generatedHits || requestTerm.hits,
        searchTerms: requestTerm.searchTerms || [],
        affiliation: requestTerm.affiliation,
        partyStatus: requestTerm.partyStatus
    };
}

export function getProductionSubscription(isDev = false): Subscription {
    return { id: isDev ? IDENTITYDEV_MAIN_ENVIRONMENT_NAME : IDENTITY_MAIN_ENVIRONMENT_NAME, displayName: IDENTITY_CONFLICTS_PRODUCTION_SUFFIX, resourceNameSuffix: "" };
}

export const IDENTITY_TEST_ENVIRONMENT_NAME = "Conflicts.Test";
export const IDENTITYDEV_TEST_ENVIRONMENT_NAME = "ConflictsDev.Test";
export const IDENTITY_CONFLICTS_TEST_SUFFIX = "Test";

export function getNonProductionSubscription(isDev = false): Subscription {
    return {
        id: isDev ? IDENTITYDEV_TEST_ENVIRONMENT_NAME : IDENTITY_TEST_ENVIRONMENT_NAME,
        displayName: IDENTITY_CONFLICTS_TEST_SUFFIX,
        resourceNameSuffix: IDENTITY_CONFLICTS_TEST_SUFFIX.toLowerCase()
    };
}

export function getTestUser(user: Partial<LoggedInUser>): LoggedInUser {
    return {
        id: user.id || "",
        name: user.name || "Test User",
        email: user.email || "testuser@aderant.com",
        role: user.role || UserRole.Standard,
        tenancy: {
            id: user.tenancy?.id || uuid(),
            uniqueName: user.tenancy?.uniqueName || "testtenancy",
            displayName: user.tenancy?.displayName || "Test Tenancy",
            subscription: user.tenancy?.subscription || { id: IDENTITY_MAIN_ENVIRONMENT_NAME, resourceNameSuffix: "", displayName: IDENTITY_CONFLICTS_PRODUCTION_SUFFIX }
        }
    };
}

export const loremIpsum =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ullamcorper elit nec mi finibus, ut hendrerit tortor vulputate. Nulla dignissim ipsum et risus vestibulum malesuada. Nullam in luctus nisi, a placerat erat. Donec pretium arcu non urna varius lacinia. Pellentesque fringilla augue a tellus consectetur posuere. Nullam malesuada erat viverra vestibulum mattis. Nunc ac luctus lacus, quis vehicula risus. Morbi ac euismod purus. Etiam malesuada, erat vel aliquam blandit, urna urna feugiat ligula, a iaculis nisi nisl eu nibh. Morbi venenatis purus nisi, a commodo sapien commodo eget. Nulla placerat luctus maximus. Cras at sapien ac justo feugiat ultrices vitae in tortor. Nam tempor diam eu odio bibendum, sed venenatis lorem ornare. Cras condimentum elementum turpis ac vestibulum. Ut lectus orci, eleifend in augue sit amet, tristique porttitor orci. Suspendisse potenti.";

export function getTextLargerThan(minSize: number): string {
    let largeText: string = loremIpsum;
    while (largeText.length < minSize) {
        largeText += loremIpsum;
    }
    return largeText;
}

export function getTestHitComment(hitComment?: Partial<HitComment>): HitComment {
    if (!hitComment) {
        hitComment = {};
    }
    return {
        id: hitComment.id ?? uuid(),
        searchId: hitComment.searchId ?? uuid(),
        versionId: hitComment.versionId ?? uuid(),
        durableHitIdentifier: {
            requestTermId: hitComment.durableHitIdentifier?.requestTermId ?? uuid(),
            hitEntityId: hitComment.durableHitIdentifier?.hitEntityId ?? uuid(),
            hitEntityType: hitComment.durableHitIdentifier?.hitEntityType ?? "Client"
        },
        text: hitComment.text ?? "Test comment",
        createdByUserId: hitComment.createdByUserId ?? uuid(),
        createdOn: hitComment.createdOn ?? new Date(),
        lastModified: hitComment.lastModified ?? new Date(),
        documentType: "HitComment"
    };
}

export function getTestHitCommentAudit(auditParam: Partial<HitCommentAudit>): HitCommentAudit {
    const audit: HitCommentAudit = {
        id: auditParam.id === "UNDEFINED" ? undefined : auditParam.id || "123",
        searchId: auditParam.searchId || "123",
        searchDocumentType: "Audit",
        searchVersionNumber: auditParam.searchVersionNumber || 1,
        actionType: auditParam.actionType || "HITCOMMENTADD",
        createdDate: auditParam.createdDate || new Date(),
        performedByUserId: auditParam.performedByUserId || "123",
        durableHitIdentifier: auditParam.durableHitIdentifier || {
            requestTermId: "a0c94ff3-7da4-4425-8ff5-3dcef826fa91",
            hitEntityType: "client",
            hitEntityId: "3"
        },
        hitCommentId: auditParam.hitCommentId || "c7111f1e-24b5-4ab0-8ac3-fb9285ca7eeb",
        text: auditParam.text || "test"
    };
    return audit;
}
