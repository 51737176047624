import {
    Audit,
    DurableHitIdentifier,
    Entity,
    getSearchSummary,
    Hit,
    HitComment,
    makeEditable,
    QuickSearch,
    SearchSummary,
    SearchSummaryNew,
    SearchVersion,
    SearchVersionNew,
    unexpectedError,
    updateSearchWithLatestEtag
} from "aderant-conflicts-models";
import { ConsoleLogger } from "@aderant/aderant-web-fw-applications";
import { assertUnreachable } from "aderant-web-fw-core";
import _ from "lodash";
import * as MyTypes from "MyTypes";
import { SaveSearchActionInputs, SaveSearchActionTypes, SearchActionType } from "state/actions";
import { Return } from "state/sagas/common";
import { getIsSearchLoaded } from "state/selectors";

export interface CurrentHitCommentState {
    durableHitIdentifier: DurableHitIdentifier;
    commentCount: number;
    reloadComments: boolean;
    comments?: HitComment[];
    // list of hit comment texts being created. Using hit comment text as id does not exist at this point
    beingCreated: string[];
    // list of hit comment ids being actioned on; includes both edits and deletes
    beingUpdated: string[];
    isLoadingFailed: boolean;
}
export interface SearchState {
    currentSearch: {
        searchVersion?: SearchVersion | SearchVersionNew | QuickSearch;
        isSavingRefCount: number;
        isPendingChanges: boolean;
        isNewSearch: boolean;
        currentHitComments?: CurrentHitCommentState;
    };
    fetchingSearchId: string;
    currentSearchActionId: string;
    areSearchSummariesLoaded: boolean;
    searchSummaries: (SearchSummary | SearchSummaryNew)[];
    errors: string[];
    //These are counts instead of a boolean to guarantee correct state when multiple dispatches are triggered.
    isSearching: number;
    areAuditsLoaded: boolean;
    searchAudits: Audit[];
    hitFlyoutDetail: {
        requestedId: string | undefined; //We need id to validate if the entity returned is the correct one. Especially when multiple dispatches fired
        entity?: Entity;
    };
    isResultDetailDialogOpen: boolean;
    isCopyFailureDialogOpen?: boolean;
    searchIdsCurrentlyProcessing: string[];
}

export const initialState: SearchState = {
    currentSearch: { searchVersion: undefined, isSavingRefCount: 0, isPendingChanges: false, isNewSearch: false, currentHitComments: undefined },
    fetchingSearchId: "",
    currentSearchActionId: "",
    areSearchSummariesLoaded: false,
    searchSummaries: [],
    errors: [],
    isSearching: 0,
    areAuditsLoaded: false,
    searchAudits: [],
    hitFlyoutDetail: {
        requestedId: undefined,
        entity: undefined
    },
    isResultDetailDialogOpen: false,
    isCopyFailureDialogOpen: false,
    searchIdsCurrentlyProcessing: []
};

const updateSearchInSummaries = (state: SearchState, searchVersion: SearchVersion | SearchVersionNew | QuickSearch): SearchState["searchSummaries"] => {
    return state.searchSummaries.map((summary) => {
        if (summary.id === searchVersion.searchId) {
            return getSearchSummary(searchVersion);
        }
        return summary;
    });
};

const addOrUpdateSearchInSummaries = (state: SearchState, searchVersion: SearchVersion | SearchVersionNew | QuickSearch): SearchState["searchSummaries"] => {
    if (state.searchSummaries.find((summary) => summary.id === searchVersion.searchId)) {
        return updateSearchInSummaries(state, searchVersion);
    } else {
        return [...state.searchSummaries, getSearchSummary(searchVersion)];
    }
};

const updateSearchSummariesById = (state: SearchState, updatedSearchSummaries: SearchSummary[]) => {
    return state.searchSummaries.map((oldSearchSummary) => {
        const updateSearchSummary = updatedSearchSummaries.findIndex((updatedsearchSummary) => updatedsearchSummary.id === oldSearchSummary.id);
        return updateSearchSummary !== -1 ? updatedSearchSummaries[updateSearchSummary] : oldSearchSummary;
    });
};

const isHitCommentForCurrentHit = (state: SearchState, hitIdentifier: DurableHitIdentifier): boolean => {
    return state.currentSearch.currentHitComments
        ? state.currentSearch.currentHitComments.durableHitIdentifier.requestTermId === hitIdentifier.requestTermId &&
              state.currentSearch.currentHitComments.durableHitIdentifier.hitEntityId === hitIdentifier.hitEntityId &&
              state.currentSearch.currentHitComments.durableHitIdentifier.hitEntityType === hitIdentifier.hitEntityType
        : false;
};

const removeHitComment = (actioningItems: string[], identifier: string) => {
    const newActioningItems = [...actioningItems];
    if (newActioningItems) {
        const index = newActioningItems.indexOf(identifier);
        if (index !== -1) {
            newActioningItems.splice(index, 1);
        }
        return newActioningItems;
    }
    return [];
};

const saveSearchReducerHandler = (state: SearchState, saveSearchActionInput: SaveSearchActionInputs): SearchState => {
    switch (saveSearchActionInput.actionType) {
        case SaveSearchActionTypes.UPDATE_TERM: {
            const { searchVersion, term } = saveSearchActionInput.payload;
            if (state.currentSearch.searchVersion) {
                let editableSearch = _.cloneDeep(state.currentSearch.searchVersion);
                if (!state.currentSearch.searchVersion.isQuickSearch) {
                    editableSearch = makeEditable(state.currentSearch.searchVersion);
                }
                const index = searchVersion.requestTerms.findIndex((t) => t.id === term.id);
                editableSearch.requestTerms[index] = saveSearchActionInput.payload.term;
                return {
                    ...state,
                    currentSearch: {
                        ...state.currentSearch,
                        searchVersion: getIsSearchLoaded(state, searchVersion.searchId) ? { ...editableSearch } : state.currentSearch.searchVersion,
                        currentHitComments: undefined
                    }
                };
            }
            return state;
        }
        case SaveSearchActionTypes.UPDATE_DETAILS: {
            if (state.currentSearch.searchVersion && !state.currentSearch.searchVersion.isQuickSearch) {
                const editableSearch = makeEditable(state.currentSearch.searchVersion);
                const updatedSearch = {
                    ...editableSearch,
                    name: saveSearchActionInput.payload.searchVersion.name,
                    requestedByUserId: saveSearchActionInput.payload.searchVersion.requestedByUserId,
                    approverUserId: saveSearchActionInput.payload.searchVersion.approverUserId,
                    description: saveSearchActionInput.payload.searchVersion.description,
                    applyFuzzySearch: saveSearchActionInput.payload.searchVersion.applyFuzzySearch
                };
                return {
                    ...state,
                    currentSearch: {
                        ...state.currentSearch,
                        searchVersion: getIsSearchLoaded(state, updatedSearch.searchId) ? updatedSearch : state.currentSearch.searchVersion,
                        currentHitComments: undefined
                    },
                    searchSummaries: updateSearchInSummaries(state, updatedSearch)
                };
            }
            return {
                ...state
            };
        }
        case SaveSearchActionTypes.DELETE_TERM: {
            const { id: versionId, searchId } = saveSearchActionInput.payload.searchVersion;
            const { termId } = saveSearchActionInput.payload;
            if (state.currentSearch.searchVersion && state.currentSearch.searchVersion.id == versionId) {
                let editableSearch = _.cloneDeep(state.currentSearch.searchVersion);
                if (!state.currentSearch.searchVersion.isQuickSearch) {
                    editableSearch = makeEditable(state.currentSearch.searchVersion);
                }
                const newTerms = [...editableSearch.requestTerms];
                _.remove(newTerms, (t) => t.id === termId);
                return {
                    ...state,
                    currentSearch: {
                        ...state.currentSearch,
                        searchVersion: getIsSearchLoaded(state, searchId) ? { ...state.currentSearch.searchVersion, requestTerms: newTerms } : state.currentSearch.searchVersion,
                        currentHitComments: undefined
                    },
                    searchSummaries: updateSearchInSummaries(state, { ...state.currentSearch.searchVersion, requestTerms: newTerms })
                };
            }
            return state;
        }
        case SaveSearchActionTypes.ADD_TERM: {
            const { searchVersion, term } = saveSearchActionInput.payload;
            if (state.currentSearch.searchVersion) {
                let editableSearch = _.cloneDeep(state.currentSearch.searchVersion);
                if (!state.currentSearch.searchVersion.isQuickSearch) {
                    editableSearch = makeEditable(state.currentSearch.searchVersion);
                }
                return {
                    ...state,
                    currentSearch: {
                        ...state.currentSearch,
                        searchVersion: getIsSearchLoaded(state, searchVersion.searchId)
                            ? { ...editableSearch, requestTerms: [...editableSearch.requestTerms, term] }
                            : state.currentSearch.searchVersion,
                        currentHitComments: undefined
                    },
                    searchSummaries: updateSearchInSummaries(state, editableSearch)
                };
            }
            return state;
        }
        case SaveSearchActionTypes.ADD_TERMS: {
            const { searchVersion, terms } = saveSearchActionInput.payload;
            if (state.currentSearch.searchVersion && !state.currentSearch.searchVersion.isQuickSearch) {
                const editableSearch = makeEditable(state.currentSearch.searchVersion);
                return {
                    ...state,
                    currentSearch: {
                        ...state.currentSearch,
                        searchVersion: getIsSearchLoaded(state, searchVersion.searchId)
                            ? { ...editableSearch, requestTerms: [...editableSearch.requestTerms, ...terms] }
                            : state.currentSearch.searchVersion,
                        currentHitComments: undefined
                    },
                    searchSummaries: updateSearchInSummaries(state, editableSearch)
                };
            }
            return state;
        }
    }
};

function getUpdatedSearchVersion(state: SearchState, durableHitIdentifier: DurableHitIdentifier, updateTo?: number, updateBy?: number) {
    const requestTerm = state.currentSearch.searchVersion?.requestTerms.find((term) => term.id === durableHitIdentifier.requestTermId);
    const hit = requestTerm?.hits?.find((hit) => hit.sourceType === durableHitIdentifier.hitEntityType && hit.sourceData.id === durableHitIdentifier.hitEntityId);
    let updatedCount = hit?.commentCount ?? 0;
    if (updateTo !== undefined) {
        updatedCount = updateTo;
    }
    if (updateBy !== undefined) {
        updatedCount += updateBy;
    }
    const updatedHit = hit ? { ...hit, commentCount: updatedCount >= 0 ? updatedCount : 0 } : undefined;
    const updatedRequestTerm = requestTerm ? { ...requestTerm, hits: requestTerm?.hits?.map((hit) => (hit.id === updatedHit?.id ? updatedHit : hit)) } : undefined;
    const updatedSearchVersion = state.currentSearch.searchVersion
        ? {
              ...state.currentSearch.searchVersion,
              requestTerms: state.currentSearch.searchVersion?.requestTerms.map((term) => (term.id === updatedRequestTerm?.id ? updatedRequestTerm : term))
          }
        : undefined;
    return updatedSearchVersion;
}

export const searchReducer = (state: SearchState = initialState, action: MyTypes.RootSearchAction): SearchState => {
    const logger = new ConsoleLogger();
    switch (action.type) {
        case SearchActionType.FETCH_LATEST_SEARCH_VERSION_SUCCESS: {
            let abandonSearch = false;
            if (state.fetchingSearchId !== action.payload.searchId) {
                abandonSearch = true;
                logger.debug(`Abandoning fetched search with searchId: ${action.payload.searchId} as another fetchSearch has been requested.`);
            }
            return {
                ...state,
                currentSearch: {
                    ...state.currentSearch,
                    searchVersion: abandonSearch ? state.currentSearch.searchVersion : action.payload,
                    currentHitComments: abandonSearch ? state.currentSearch.currentHitComments : undefined
                },
                fetchingSearchId: abandonSearch ? state.fetchingSearchId : "",
                searchSummaries: updateSearchInSummaries(state, action.payload)
            };
        }
        case SearchActionType.FETCH_SEARCH_SUMMARIES: {
            return {
                ...state,
                areSearchSummariesLoaded: false
            };
        }
        case SearchActionType.FETCH_SEARCH_SUMMARIES_SUCCESS: {
            return {
                ...state,
                areSearchSummariesLoaded: true,
                searchSummaries: action.payload
            };
        }
        case SearchActionType.FETCH_SEARCH_SUMMARIES_FAILURE: {
            return {
                ...state,
                areSearchSummariesLoaded: true
            };
        }
        case SearchActionType.UPDATE_SEARCH_SUMMARIES: {
            return {
                ...state,
                searchSummaries: updateSearchInSummaries(state, action.payload)
            };
        }
        case SearchActionType.ADD_SEARCH: {
            return {
                ...state,
                searchSummaries: action.payload.newSearchVersion.isQuickSearch ? [...state.searchSummaries] : [...state.searchSummaries, getSearchSummary(action.payload.newSearchVersion)],
                currentSearch: {
                    ...state.currentSearch,
                    searchVersion: state.currentSearch.searchVersion
                        ? action.payload.setAsCurrentSearch === false
                            ? state.currentSearch.searchVersion
                            : action.payload.newSearchVersion
                        : action.payload.newSearchVersion,
                    currentHitComments: undefined
                }
            };
        }
        case SearchActionType.ADD_SEARCH_SUCCESS: {
            const updatedSearch = action.payload.newSearchVersion;
            return {
                ...state,
                searchSummaries: action.payload.newSearchVersion.isQuickSearch ? [...state.searchSummaries] : updateSearchInSummaries(state, updatedSearch),
                currentSearch: { ...state.currentSearch, searchVersion: getIsSearchLoaded(state, updatedSearch.searchId) ? updatedSearch : state.currentSearch.searchVersion }
            };
        }
        case SearchActionType.ADD_SEARCH_COPY_FROM_ID: {
            return {
                ...state,
                fetchingSearchId: action.payload.newSearchId
            };
        }
        case SearchActionType.ADD_SEARCH_COPY_FROM_ID_SUCCESS: {
            const updatedSearch = action.payload.newSearchVersion;
            return {
                ...state,
                fetchingSearchId: "",
                searchSummaries: [...state.searchSummaries, getSearchSummary(updatedSearch)],
                currentSearch: { ...state.currentSearch, searchVersion: updatedSearch, currentHitComments: undefined },
                isCopyFailureDialogOpen: false
            };
        }
        case SearchActionType.ADD_SEARCH_COPY_FROM_ID_FAILURE: {
            return {
                ...state,
                fetchingSearchId: "",
                isCopyFailureDialogOpen: true,
                currentSearch: { ...state.currentSearch, searchVersion: action.payload.originalSearchVersion }
            };
        }
        case SearchActionType.CLOSE_COPY_FAILURE_DIALOG: {
            return {
                ...state,
                isCopyFailureDialogOpen: false
            };
        }
        case SearchActionType.CLEAR_CURRENT_SEARCH: {
            return {
                ...state,
                currentSearch: { ...state.currentSearch, searchVersion: undefined, currentHitComments: undefined }
            };
        }
        case SearchActionType.CLEAR_SEARCH_FOR_REPERFORMING: {
            if (state.currentSearch.searchVersion?.isQuickSearch) {
                return {
                    ...state,
                    currentSearch: {
                        ...state.currentSearch,
                        searchVersion: { ...state.currentSearch.searchVersion, status: "DRAFT", editState: "UNSAVED" },
                        currentHitComments: undefined
                    }
                };
            }
            return {
                ...state
            };
        }
        case SearchActionType.CREATE_NEW_SEARCH_VERSION_FAILURE:
        case SearchActionType.ADD_SEARCH_FAILURE:
        case SearchActionType.DELETE_SEARCHES:
        case SearchActionType.DELETE_SEARCHES_FAILURE: {
            return {
                ...state // todo: we need to somehow remove the search that failed to save - TASK 931
            };
        }
        case SearchActionType.DELETE_SEARCHES_SUCCESS: {
            const newSearches = [...state.searchSummaries];
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [searchesToDelete, searchesToKeep] = _.partition(newSearches, (s: SearchSummary | SearchSummaryNew) => action.payload.includes(s.id));

            return {
                ...state,
                searchSummaries: searchesToKeep
            };
        }
        case SearchActionType.IS_CURRENT_SEARCH_SAVING: {
            if (!action.payload && state.currentSearch.isSavingRefCount === 0) {
                throw unexpectedError(`Search saving error for ${state.currentSearch}, isSavingRefCount is less than 0`, "currentSearch.isSavingRefCount should not be less than 0.");
            }
            return {
                ...state,
                currentSearch: { ...state.currentSearch, isSavingRefCount: action.payload ? state.currentSearch.isSavingRefCount + 1 : state.currentSearch.isSavingRefCount - 1 }
            };
        }
        case SearchActionType.IS_CURRENT_SEARCH_PENDING_CHANGES: {
            return {
                ...state,
                currentSearch: { ...state.currentSearch, isPendingChanges: action.payload }
            };
        }
        case SearchActionType.IS_SEARCHING: {
            return {
                ...state,
                isSearching: action.payload ? state.isSearching + 1 : state.isSearching - 1
            };
        }
        case SearchActionType.IS_NEW_SEARCH: {
            return {
                ...state,
                currentSearch: { ...state.currentSearch, isNewSearch: action.payload }
            };
        }
        case SearchActionType.PERFORM_SEARCH_SUCCESS: {
            return {
                ...state,
                currentSearch: { ...state.currentSearch, searchVersion: undefined, currentHitComments: undefined },
                searchSummaries: updateSearchInSummaries(state, action.payload)
            };
        }
        case SearchActionType.PERFORM_QUICK_SEARCH_AGAIN: {
            const summaryToUpdate = state.searchSummaries.find((summary) => summary.id === action.payload.searchId.searchId);
            return {
                ...state,
                // justification: summaries are readonly as we expect them to be consistent with Cosmos.
                // this will get overwritten by the success action OR reverted by the failure.
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                searchSummaries: updateSearchSummariesById(state, [{ ...summaryToUpdate, status: "SEARCHING" } as SearchSummary])
            };
        }
        case SearchActionType.PERFORM_QUICK_SEARCH_SUCCESS: {
            return {
                ...state,
                currentSearch: { ...state.currentSearch, searchVersion: undefined, currentHitComments: undefined },
                searchSummaries: state.searchSummaries.find((summary) => summary.id === action.payload.searchId)
                    ? updateSearchInSummaries(state, action.payload)
                    : [...state.searchSummaries, getSearchSummary(action.payload)]
            };
        }
        case SearchActionType.PERFORM_QUICK_SEARCH_AGAIN_FAILURE: {
            const summaryToUpdate = state.searchSummaries.find((summary) => summary.id === action.payload.searchId.searchId);
            return {
                ...state,
                // justification: we are manually reverting a readonly summary, that has been altered by perform quick search again
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                searchSummaries: updateSearchSummariesById(state, [{ ...summaryToUpdate, status: action.payload.previousStatus } as SearchSummary]),
                errors: [...state.errors, action.payload.error]
            };
        }
        case SearchActionType.SAVE_QUICK_SEARCH_SUCCESS: {
            return {
                ...state,
                searchSummaries: addOrUpdateSearchInSummaries(state, action.payload),
                currentSearch: {
                    ...state.currentSearch,
                    searchVersion: getIsSearchLoaded(state, action.payload.searchId) ? action.payload : state.currentSearch.searchVersion,
                    currentHitComments: undefined
                }
            };
        }
        case SearchActionType.PERFORM_QUICK_SEARCH_FAILURE:
        case SearchActionType.PERFORM_SEARCH_FAILURE: {
            return {
                ...state,
                errors: [...state.errors, action.payload]
            };
        }
        case SearchActionType.ADD_TERM_THEN_PERFORM_SEARCH: {
            return {
                ...state
            };
        }
        case SearchActionType.ADD_TERM_THEN_PERFORM_SEARCH_FAILURE: {
            return {
                ...state,
                errors: [...state.errors, action.payload]
            };
        }
        case SearchActionType.ADD_TERM_THEN_PERFORM_SEARCH_SUCCESS: {
            return {
                ...state,
                currentSearch: {
                    ...state.currentSearch,
                    searchVersion: getIsSearchLoaded(state, action.payload.searchId) ? action.payload : state.currentSearch.searchVersion,
                    currentHitComments: undefined
                },
                searchSummaries: updateSearchInSummaries(state, action.payload)
            };
        }
        case SearchActionType.ADD_TERM_THEN_SUBMIT_SEARCH: {
            return {
                ...state
            };
        }
        case SearchActionType.ADD_TERM_THEN_SUBMIT_SEARCH_FAILURE: {
            return {
                ...state,
                errors: [...state.errors, action.payload]
            };
        }
        case SearchActionType.ADD_TERM_THEN_SUBMIT_SEARCH_SUCCESS: {
            return {
                ...state,
                currentSearch: {
                    ...state.currentSearch,
                    searchVersion: getIsSearchLoaded(state, action.payload.searchId) ? action.payload : state.currentSearch.searchVersion,
                    currentHitComments: undefined
                },
                searchSummaries: updateSearchInSummaries(state, action.payload)
            };
        }
        case SearchActionType.CONVERT_TO_FULL_SEARCH: {
            return {
                ...state,
                fetchingSearchId: action.payload.newSearchId
            };
        }
        case SearchActionType.FETCH_SEARCH_SUMMARIES_BY_ID:
        case SearchActionType.CLEAN_UP_SEARCHING_SEARCHES:
        case SearchActionType.CREATE_AND_PERFORM_NEW_SEARCH_VERSION:
        case SearchActionType.UPDATE_SEARCH:
        case SearchActionType.UPDATE_HITS:
        case SearchActionType.UPDATE_SEARCHES_ASSIGNED_TO:
        case SearchActionType.UPDATE_SEARCHES_SEARCH_STATUS: {
            return state;
        }
        case SearchActionType.FETCH_SEARCH_SUMMARIES_BY_ID_SUCCESS: {
            const updatedSearchSummaries = updateSearchSummariesById(state, action.payload);
            if (_.isEqual(state.searchSummaries, updatedSearchSummaries)) {
                return state;
            }
            return {
                ...state,
                currentSearch: { ...state.currentSearch, searchVersion: undefined, currentHitComments: undefined },
                searchSummaries: updatedSearchSummaries
            };
        }
        case SearchActionType.UPDATE_HITS_SUCCESS: {
            // Modify payload so that client recieves more than just the returned updated hit documents.
            // This will be addressed properly in a seperate PR,
            // however to speed performance improvements for release, this quick workaround is included.
            const editableSearchPayload = makeEditable(action.payload.searchVersion);
            const requestTermsInSearchState = state.currentSearch.searchVersion?.requestTerms || [];
            //If we have a current hit, we need to update the comment count and reload comments if the current hit is in the updated hits
            const durableHitIdentifierInSearchState = state.currentSearch.currentHitComments?.durableHitIdentifier;
            let updatedCurrentHit: Hit | undefined;

            editableSearchPayload.requestTerms.forEach((requestTerm) => {
                const requestTermInSearchState = requestTermsInSearchState.find((term) => requestTerm.id === term.id);
                if (durableHitIdentifierInSearchState && durableHitIdentifierInSearchState.requestTermId === requestTerm.id) {
                    updatedCurrentHit = requestTerm.hits?.find(
                        (hit) => hit.sourceType === durableHitIdentifierInSearchState.hitEntityType && hit.sourceData.id === durableHitIdentifierInSearchState.hitEntityId
                    );
                }
                if (requestTerm.hits?.length !== requestTermInSearchState?.hits?.length) {
                    requestTermInSearchState?.hits?.forEach((hit) => {
                        if (!requestTerm.hits?.find((currentHit) => currentHit.id === hit.id)) {
                            requestTerm.hits?.push(hit);
                        }
                    });
                }
            });
            return {
                ...state,
                currentSearch: {
                    ...state.currentSearch,
                    searchVersion: getIsSearchLoaded(state, action.payload.searchVersion.searchId) ? editableSearchPayload : state.currentSearch.searchVersion,
                    currentHitComments: updatedCurrentHit
                        ? { ...state.currentSearch.currentHitComments!, commentCount: updatedCurrentHit.commentCount ?? 0, reloadComments: true }
                        : state.currentSearch.currentHitComments
                },
                searchSummaries: updateSearchInSummaries(state, editableSearchPayload)
            };
        }
        case SearchActionType.CONVERT_TO_FULL_SEARCH_SUCCESS:
            const summaries = [...state.searchSummaries];
            _.remove(summaries, (summary) => summary.id === action.payload.quickSearchId);
            summaries.push(getSearchSummary(action.payload.newSearchRequest));
            return {
                ...state,
                fetchingSearchId: "",
                searchSummaries: summaries,
                currentSearch: {
                    ...state.currentSearch,
                    searchVersion: action.payload.newSearchRequest,
                    currentHitComments: undefined
                }
            };
        case SearchActionType.CONVERT_TO_FULL_SEARCH_FAILURE: {
            return {
                ...state,
                fetchingSearchId: "",
                errors: [...state.errors, action.payload]
            };
        }
        case SearchActionType.FETCH_SEARCH_SUMMARIES_BY_ID_FAILURE:
        case SearchActionType.CREATE_AND_PERFORM_NEW_SEARCH_VERSION_FAILURE:
        case SearchActionType.UPDATE_SEARCH_FAILURE:
        case SearchActionType.UPDATE_HITS_FAILURE:
        case SearchActionType.UPDATE_SEARCHES_ASSIGNED_TO_FAILURE:
        case SearchActionType.UPDATE_SEARCHES_SEARCH_STATUS_FAILURE:
        case SearchActionType.SEARCH_ACTION_FAILURE: {
            return {
                ...state,
                errors: [...state.errors, action.payload]
            };
        }
        case SearchActionType.UPDATE_SEARCH_SUCCESS: {
            let updatedSearch: SearchVersion | SearchVersionNew | undefined;
            let updatedSearchIsCurrentSearch = false;
            if (state.currentSearch.searchVersion?.isQuickSearch) {
                return state;
            }
            if (action.payload.versionId.id === state.currentSearch.searchVersion?.id) {
                updatedSearch = { ...state.currentSearch.searchVersion, ...action.payload.change };
                if (action.payload.versionId.etag) {
                    updatedSearch = updateSearchWithLatestEtag(updatedSearch, action.payload.versionId.etag);
                }
                updatedSearchIsCurrentSearch = true;
            }
            return {
                ...state,
                searchSummaries: updatedSearch
                    ? updateSearchInSummaries(state, updatedSearch)
                    : state.searchSummaries.map((summary) => {
                          if (summary.versionId === action.payload.versionId.id) {
                              return {
                                  ...summary,
                                  ...action.payload.change
                              };
                          }
                          return summary;
                      }),
                currentSearch: updatedSearchIsCurrentSearch ? { ...state.currentSearch, searchVersion: updatedSearch, currentHitComments: undefined } : state.currentSearch
            };
        }
        case SearchActionType.CLEAN_UP_SEARCHING_SEARCHES_SUCCESS:
        case SearchActionType.UPDATE_SEARCHES_ASSIGNED_TO_SUCCESS:
        case SearchActionType.UPDATE_SEARCHES_SEARCH_STATUS_SUCCESS: {
            let updatedSearch: SearchVersion | SearchVersionNew | undefined;
            let updatedSearchIsCurrentSearch = false;
            if (state.currentSearch.searchVersion?.isQuickSearch) {
                return state;
            }

            const currentSearchMatch = action.payload.versionIds.find((versionIdWithEtag) => versionIdWithEtag.versionId === state.currentSearch.searchVersion?.id);
            const versionIds = action.payload.versionIds.map((versionIdWithEtag) => versionIdWithEtag.versionId);
            if (state.currentSearch.searchVersion && currentSearchMatch) {
                updatedSearch = { ...state.currentSearch.searchVersion, ...action.payload.change };
                if (currentSearchMatch.etag) {
                    updatedSearch = updateSearchWithLatestEtag(updatedSearch, currentSearchMatch.etag);
                }
                updatedSearchIsCurrentSearch = true;
            }
            return {
                ...state,
                searchSummaries: state.searchSummaries.map((summary) => {
                    if (versionIds.includes(summary.versionId)) {
                        const searchInfo = action.payload.versionIds.find((versionIdWithEtag) => versionIdWithEtag.versionId === summary.versionId);
                        return {
                            ...summary,
                            ...action.payload.change,
                            searchEtag: searchInfo && searchInfo.etag ? searchInfo.etag : ""
                        };
                    }
                    return summary;
                }),
                currentSearch: updatedSearchIsCurrentSearch ? { ...state.currentSearch, searchVersion: updatedSearch, currentHitComments: undefined } : state.currentSearch
            };
        }
        case SearchActionType.FETCH_AUDITS: {
            return {
                ...state,
                areAuditsLoaded: false
            };
        }
        case SearchActionType.CLEAR_AUDITS: {
            return {
                ...state,
                areAuditsLoaded: false,
                searchAudits: []
            };
        }
        case SearchActionType.FETCH_AUDITS_FAILURE: {
            return { ...state, areAuditsLoaded: true };
        }
        case SearchActionType.FETCH_LATEST_SEARCH_VERSION_FAILURE: {
            return {
                ...state,
                fetchingSearchId: state.fetchingSearchId === action.payload.searchId ? "" : state.fetchingSearchId
            };
        }
        case SearchActionType.FETCH_LATEST_SEARCH_VERSION: {
            return {
                ...state,
                currentSearch: { ...state.currentSearch, searchVersion: undefined, currentHitComments: undefined },
                fetchingSearchId: action.payload.searchId
            };
        }
        case SearchActionType.FOCUS_HIT: {
            if (!state.currentSearch.searchVersion || state.currentSearch.searchVersion.searchId !== action.payload.searchId || state.currentSearch.searchVersion.id !== action.payload.versionId) {
                //we've cleared the search or have loaded a different search since calling focus, do nothing
                return state;
            }
            if (isHitCommentForCurrentHit(state, action.payload.hitIdentifier)) {
                //already focused, so currentHitComments should already be set
                return state;
            }

            const requestTerm = state.currentSearch.searchVersion?.requestTerms.find((term) => term.id === action.payload.hitIdentifier.requestTermId);
            const hit = requestTerm?.hits?.find((hit) => hit.sourceType === action.payload.hitIdentifier.hitEntityType && hit.sourceData.id === action.payload.hitIdentifier.hitEntityId);
            return {
                ...state,
                currentSearch: {
                    ...state.currentSearch,
                    currentHitComments: {
                        durableHitIdentifier: action.payload.hitIdentifier,
                        reloadComments: true,
                        commentCount: hit?.commentCount ?? 0,
                        comments: undefined,
                        beingCreated: [],
                        beingUpdated: [],
                        isLoadingFailed: false
                    }
                }
            };
        }
        case SearchActionType.FETCH_HIT_COMMENTS: {
            if (!state.currentSearch.searchVersion || state.currentSearch.searchVersion.searchId !== action.payload.searchId) {
                //we've cleared the search or have loaded a different search since calling focus, do nothing
                return state;
            }
            if (isHitCommentForCurrentHit(state, action.payload.hitIdentifier)) {
                return {
                    ...state,
                    currentSearch: {
                        ...state.currentSearch,
                        //Don't set comment count to 0 as it will hide the comment icon when you are on the overview tab and then click the comment tab in the flyout
                        currentHitComments: { ...state.currentSearch.currentHitComments!, reloadComments: false, comments: undefined, beingCreated: [], beingUpdated: [] }
                    }
                };
            }
            return state;
        }
        case SearchActionType.CREATE_HIT_COMMENT: {
            if (isHitCommentForCurrentHit(state, action.payload.hitComment.durableHitIdentifier)) {
                if (state.currentSearch.currentHitComments) {
                    return {
                        ...state,
                        currentSearch: {
                            ...state.currentSearch,
                            currentHitComments: { ...state.currentSearch.currentHitComments, beingCreated: [...state.currentSearch.currentHitComments.beingCreated, action.payload.hitComment.text] }
                        }
                    };
                }
            }
            return state;
        }
        case SearchActionType.EDIT_HIT_COMMENT: {
            if (isHitCommentForCurrentHit(state, action.payload.durableHitIdentifier)) {
                if (state.currentSearch.currentHitComments) {
                    return {
                        ...state,
                        currentSearch: {
                            ...state.currentSearch,
                            currentHitComments: {
                                ...state.currentSearch.currentHitComments,
                                beingUpdated: [...state.currentSearch.currentHitComments.beingUpdated, action.payload.editHitCommentInput.hitCommentId]
                            }
                        }
                    };
                }
            }
            return state;
        }
        case SearchActionType.DELETE_HIT_COMMENT: {
            if (isHitCommentForCurrentHit(state, action.payload.durableHitIdentifier)) {
                if (state.currentSearch.currentHitComments) {
                    return {
                        ...state,
                        currentSearch: {
                            ...state.currentSearch,
                            currentHitComments: {
                                ...state.currentSearch.currentHitComments,
                                beingUpdated: [...state.currentSearch.currentHitComments.beingUpdated, action.payload.hitCommentCosmosId.hitCommentId]
                            }
                        }
                    };
                }
            }
            return state;
        }
        case SearchActionType.PERFORM_QUICK_SEARCH:
        case SearchActionType.PERFORM_SEARCH:
        case SearchActionType.CREATE_NEW_SEARCH_VERSION:
        case SearchActionType.SUBMIT_SEARCH: {
            return state;
        }
        case SearchActionType.CREATE_NEW_SEARCH_VERSION_SUCCESS:
        case SearchActionType.SUBMIT_SEARCH_SUCCESS: {
            const updatedSearch = action.payload;
            return {
                ...state,
                searchSummaries: state.searchSummaries.map((summary) => {
                    if (summary.id === updatedSearch.searchId) {
                        return getSearchSummary(updatedSearch);
                    }
                    return summary;
                }),
                currentSearch: {
                    ...state.currentSearch,
                    searchVersion: getIsSearchLoaded(state, updatedSearch?.searchId) ? updatedSearch : state.currentSearch.searchVersion,
                    currentHitComments: undefined
                }
            };
        }
        case SearchActionType.SAVE_SEARCH_FAILURE:
        case SearchActionType.SUBMIT_SEARCH_FAILURE: {
            return {
                ...state,
                errors: [...state.errors, action.payload]
            };
        }
        case SearchActionType.FETCH_AUDITS_SUCCESS: {
            return {
                ...state,
                searchAudits: action.payload,
                areAuditsLoaded: true
            };
        }
        case SearchActionType.FETCH_ENTITY_DETAILS: {
            return {
                ...state,
                hitFlyoutDetail: {
                    ...state.hitFlyoutDetail,
                    requestedId: action.payload.id //capture the id for validation later
                }
            };
        }
        case SearchActionType.FETCH_ENTITY_DETAILS_SUCCESS: {
            const isCurrentRequestId = state.hitFlyoutDetail.requestedId === action.payload.entity.id;
            return {
                ...state,
                hitFlyoutDetail: {
                    entity: isCurrentRequestId ? action.payload.entity : undefined,
                    requestedId: isCurrentRequestId ? undefined : state.hitFlyoutDetail.requestedId //Reset requestedId if response for valid entity success
                }
            };
        }
        case SearchActionType.FETCH_ENTITY_DETAILS_FAILURE: {
            const isCurrentRequestId = state.hitFlyoutDetail.requestedId === action.payload.requestedId;
            return {
                ...state,
                errors: [...state.errors, action.payload.error],
                hitFlyoutDetail: {
                    entity: undefined,
                    requestedId: isCurrentRequestId ? undefined : state.hitFlyoutDetail.requestedId //Reset requestedId if response for valid entity fetch failure
                }
            };
        }
        case SearchActionType.OPEN_RESULT_DETAIL_DIALOG: {
            return {
                ...state,
                isResultDetailDialogOpen: true
            };
        }
        case SearchActionType.CLOSE_RESULT_DETAIL_DIALOG: {
            return {
                ...state,
                isResultDetailDialogOpen: false
            };
        }
        case SearchActionType.CREATE_AND_PERFORM_NEW_SEARCH_VERSION_SUCCESS: {
            return {
                ...state,
                currentSearch: { ...state.currentSearch, searchVersion: getIsSearchLoaded(state, action.payload.searchId) ? action.payload : state.currentSearch.searchVersion },
                searchSummaries: updateSearchInSummaries(state, action.payload)
            };
        }
        case SearchActionType.SAVE_SEARCH: {
            return saveSearchReducerHandler(state, action.payload.saveSearchActionInput);
        }
        case SearchActionType.SAVE_SEARCH_SUCCESS: {
            return {
                ...state,
                currentSearch: {
                    ...state.currentSearch,
                    searchVersion: getIsSearchLoaded(state, action.payload.searchVersion.searchId) ? action.payload.searchVersion : state.currentSearch.searchVersion
                },
                searchSummaries: updateSearchInSummaries(state, action.payload.searchVersion)
            };
        }
        case SearchActionType.ADD_SEARCH_IDS_CURRENTLY_PROCESSING: {
            return {
                ...state,
                searchIdsCurrentlyProcessing: [...state.searchIdsCurrentlyProcessing, ...action.payload]
            };
        }
        case SearchActionType.REMOVE_SEARCH_IDS_CURRENTLY_PROCESSING: {
            const removeSearchIdsForProcessing = state.searchIdsCurrentlyProcessing.filter((searchId) => !action.payload.includes(searchId));
            return {
                ...state,
                searchIdsCurrentlyProcessing: removeSearchIdsForProcessing
            };
        }
        case SearchActionType.FETCH_HIT_COMMENTS_SUCCESS: {
            if (isHitCommentForCurrentHit(state, action.payload.hitIdentifier)) {
                let updatedSearchVersion: Return<typeof getUpdatedSearchVersion> | undefined = undefined;
                //if above condition is true, then currentHitComments is defined
                if (state.currentSearch.currentHitComments!.commentCount !== action.payload.hitComments.length) {
                    //Update the count in the hit on the requestTerm on the searchVersion (for the grid icon),
                    updatedSearchVersion = getUpdatedSearchVersion(state, action.payload.hitIdentifier, action.payload.hitComments.length);
                }
                return {
                    ...state,
                    currentSearch: {
                        ...state.currentSearch,
                        searchVersion: updatedSearchVersion ?? state.currentSearch.searchVersion,
                        currentHitComments: {
                            ...state.currentSearch.currentHitComments!,
                            comments: action.payload.hitComments,
                            reloadComments: false,
                            // update the count in the currentHitComments (for the flyout icon)
                            commentCount: action.payload.hitComments.length,
                            beingCreated: [],
                            beingUpdated: [],
                            isLoadingFailed: false
                        }
                    }
                };
            }
            return state;
        }
        case SearchActionType.FETCH_HIT_COMMENTS_FAILURE: {
            if (isHitCommentForCurrentHit(state, action.payload.durableHitIdentifier)) {
                return {
                    ...state,
                    currentSearch: {
                        ...state.currentSearch,
                        currentHitComments: {
                            comments: undefined,
                            durableHitIdentifier: action.payload.durableHitIdentifier,
                            reloadComments: false,
                            commentCount: 0,
                            beingCreated: [],
                            beingUpdated: [],
                            isLoadingFailed: true
                        }
                    },
                    errors: [...state.errors, action.payload.error]
                };
            }
            return state;
        }
        case SearchActionType.CREATE_HIT_COMMENT_SUCCESS: {
            if (isHitCommentForCurrentHit(state, action.payload.hitComment.durableHitIdentifier)) {
                //Update the count in the hit on the requestTerm on the searchVersion (for the grid icon),
                // and update the count in the currentHitComments (for the flyout icon)
                const updatedSearchVersion = getUpdatedSearchVersion(state, action.payload.hitComment.durableHitIdentifier, undefined, 1);
                return {
                    ...state,
                    currentSearch: {
                        ...state.currentSearch,
                        searchVersion: updatedSearchVersion ?? state.currentSearch.searchVersion,
                        currentHitComments: {
                            //if above condition is true, then currentHitComments is defined
                            ...state.currentSearch.currentHitComments!,
                            comments: [
                                ...(state.currentSearch.currentHitComments!.comments ? [...state.currentSearch.currentHitComments!.comments, action.payload.hitComment] : [action.payload.hitComment])
                            ],
                            commentCount: (state.currentSearch.currentHitComments!.commentCount ?? 0) + 1,
                            beingCreated: removeHitComment(state.currentSearch.currentHitComments!.beingCreated, action.payload.hitComment.text)
                        }
                    }
                };
            }
            return state;
        }
        case SearchActionType.CREATE_HIT_COMMENT_FAILURE: {
            if (isHitCommentForCurrentHit(state, action.payload.durableHitIdentifier)) {
                return {
                    ...state,
                    currentSearch: {
                        ...state.currentSearch,
                        currentHitComments: {
                            //if above condition is true, then currentHitComments is defined
                            ...state.currentSearch.currentHitComments!,
                            beingCreated: removeHitComment(state.currentSearch.currentHitComments!.beingCreated, action.payload.hitCommentText)
                        }
                    },
                    errors: [...state.errors, action.payload.error]
                };
            }
            return state;
        }
        case SearchActionType.EDIT_HIT_COMMENT_SUCCESS: {
            if (isHitCommentForCurrentHit(state, action.payload.hitComment.durableHitIdentifier)) {
                if (state.currentSearch.currentHitComments?.comments) {
                    return {
                        ...state,
                        currentSearch: {
                            ...state.currentSearch,
                            currentHitComments: {
                                ...state.currentSearch.currentHitComments,
                                comments: state.currentSearch.currentHitComments.comments.map((comment) => (comment.id === action.payload.hitComment.id ? action.payload.hitComment : comment)),
                                beingUpdated: removeHitComment(state.currentSearch.currentHitComments.beingUpdated, action.payload.hitComment.id)
                            }
                        }
                    };
                }
            }
            return state;
        }
        case SearchActionType.UPDATE_HIT_COMMENT_FAILURE: {
            if (isHitCommentForCurrentHit(state, action.payload.durableHitIdentifier)) {
                if (state.currentSearch.currentHitComments?.comments) {
                    return {
                        ...state,
                        currentSearch: {
                            ...state.currentSearch,
                            currentHitComments: {
                                ...state.currentSearch.currentHitComments,
                                beingUpdated: removeHitComment(state.currentSearch.currentHitComments.beingUpdated, action.payload.hitCommentId)
                            }
                        },
                        errors: [...state.errors, action.payload.error]
                    };
                }
            }
            return state;
        }
        case SearchActionType.DELETE_HIT_COMMENT_SUCCESS: {
            if (isHitCommentForCurrentHit(state, action.payload.durableHitIdentifier)) {
                //Update the count in the hit on the requestTerm on the searchVersion (for the grid icon) and update the count in the currentHitComments (for the flyout icon)
                const updatedSearchVersion = getUpdatedSearchVersion(state, action.payload.durableHitIdentifier, undefined, -1);
                if (state.currentSearch.currentHitComments?.comments) {
                    return {
                        ...state,
                        currentSearch: {
                            ...state.currentSearch,
                            searchVersion: updatedSearchVersion ?? state.currentSearch.searchVersion,
                            currentHitComments: {
                                ...state.currentSearch.currentHitComments,
                                comments: state.currentSearch.currentHitComments.comments.filter((comment) => comment.id !== action.payload.hitCommentCosmosId.hitCommentId) ?? [],
                                commentCount: (state.currentSearch.currentHitComments.commentCount ?? 0) > 0 ? state.currentSearch.currentHitComments.commentCount - 1 : 0,
                                beingUpdated: removeHitComment(state.currentSearch.currentHitComments.beingUpdated, action.payload.hitCommentCosmosId.hitCommentId)
                            }
                        }
                    };
                }
            }
            return state;
        }
    }
    /**sometimes redux will throw internal actions at reducers (e.g. @@redux/INIT), and it expects you to send back the state unchanged
    /*so our "exhaustive" switch statement isn't actually exhaustive. We want to return the current state *at runtime* if we get an unknown action
    /*to conform with redux expectations/best practices, but it's nice to get a compile time error if we haven't explicitly handled any of our own actions
    /*
    /*the wacky "if undefined || not undefined" statement is because just having a non conditional return with a line after it is not valid code. 
    */
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    if (typeof (action as any).type === "undefined" || typeof (action as any).type !== "undefined") {
        return state;
    }
    assertUnreachable(action);
};
