import { Backdrop, CircularProgress } from "@mui/material";
import { isCompleteSearchStatus, isSearchedStatus, isUnsearchedSearchStatus, QuickSearch, SearchStatuses, SearchVersion, SearchVersionNew, User } from "aderant-conflicts-models";
import PageContent from "components/PageContainer/PageContent";
import { RootState } from "MyTypes";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router";
import { searchActions } from "state/actions";
import { getCurrentSearch, getCurrentUser, getIsCurrentSearchQuickSearch, getIsFetchingCurrentSearch, getIsNewSearch, getIsSearching, getIsSearchLoaded } from "state/selectors";
import { conflictsStyles } from "styles/conflictsStyles";
import { PathMap } from "utilities/routingPathMap";

type SearchRouteProps = {
    path: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render: (props?: any) => JSX.Element;
};

export function resultsPageRedirectConditions(searchVersion: SearchVersion | SearchVersionNew | QuickSearch): string {
    if (searchVersion.status && isUnsearchedSearchStatus(searchVersion.status) && searchVersion.isQuickSearch) {
        return `${PathMap.searchRequest}${searchVersion.searchId}${PathMap.quickSearch}`;
    }
    if (searchVersion.status && isUnsearchedSearchStatus(searchVersion.status)) {
        return `${PathMap.searchRequest}${searchVersion.searchId}`;
    }

    return "";
}

export function searchRequestPageRedirectConditions(searchVersion: SearchVersion | SearchVersionNew | QuickSearch): string {
    if (isSearchedStatus(searchVersion.status)) {
        return `${PathMap.results}${searchVersion.searchId}`;
    }
    return "";
}

export function newVersionRedirectConditions(searchVersion: SearchVersion | SearchVersionNew | QuickSearch, user?: User): string {
    if (searchVersion.isQuickSearch) {
        //Quick searches do not allow new versions
        return "";
    }

    if ((searchVersion.assignedToUserId !== user?.id && isSearchedStatus(searchVersion.status)) || isCompleteSearchStatus(searchVersion.status)) {
        return `${PathMap.results}${searchVersion.searchId}`;
    }

    if (searchVersion.status === "SUBMITTED") {
        if (!searchVersion.number || searchVersion.assignedToUserId !== user?.id) {
            return `${PathMap.searchRequests}`;
        } else {
            return `${PathMap.searchRequest}${searchVersion.searchId}`;
        }
    }

    if (searchVersion.status === "DRAFT") {
        return `${PathMap.searchRequests}`;
    }

    return "";
}

export function quickSearchRedirectConditions(searchVersion: SearchVersion | SearchVersionNew | QuickSearch): string {
    if (!searchVersion.isQuickSearch) {
        return `${PathMap.searchRequest}${searchVersion.searchId}`;
    }

    return "";
}

/*
 * Redirects to the search requests page.
 * When called with a searchVersion, will detemine if the search is a quick search or not, and redirect to the quick searches page instead, if it is.
 */
export function searchRequestsRedirectConditions(searchVersion: SearchVersion | SearchVersionNew | QuickSearch | undefined): string {
    if (searchVersion && searchVersion.isQuickSearch) {
        return PathMap.quickSearches;
    }
    return PathMap.searchRequests;
}

export default function SearchRoute(routeProps: SearchRouteProps): JSX.Element {
    const location = useLocation();
    const dispatch = useDispatch();
    const locationSplit = location.pathname.split("/").slice(1);
    const currentPath = locationSplit[0];
    const id = locationSplit[1];
    const urlSearchType: string | false = locationSplit[2] ?? false;
    const searchVersion: SearchVersion | SearchVersionNew | QuickSearch | undefined = useSelector((rootState: RootState) => getCurrentSearch(rootState), shallowEqual);
    const isCurrentSearchLoaded: boolean = useSelector((rootState: RootState) => getIsSearchLoaded(rootState.search, id));
    const isFetchingCurrentSearch: boolean = useSelector((rootState: RootState) => getIsFetchingCurrentSearch(rootState, id));
    const isCurrentSearchQuickSearch = useSelector(getIsCurrentSearchQuickSearch);
    const isSearching: boolean = useSelector(getIsSearching);
    const isNewSearch: boolean = useSelector(getIsNewSearch);
    const user: User | undefined = useSelector(getCurrentUser, shallowEqual);
    const newVersion = urlSearchType === PathMap.newVersion.substring(1);
    const quickSearch = urlSearchType === PathMap.quickSearch.substring(1);
    const [showNotFound, setShowNotFound] = useState(false);

    useEffect(() => {
        if (!isCurrentSearchLoaded && !isFetchingCurrentSearch && !isNewSearch) {
            if (newVersion) {
                dispatch(searchActions.fetchLatestSearchVersion({ searchId: id, fetchHitResults: false }));
            } else {
                dispatch(searchActions.fetchLatestSearchVersion({ searchId: id, fetchHitResults: true }));
            }
        }
        //This should be set to true once we have dispatched fetchLatestSearchVersion. Without this, the not found message is rendered before this useEffect even runs
        //causing a short glitch of the not found message
        setShowNotFound(true);
    }, [id, isCurrentSearchLoaded, isNewSearch, newVersion]);

    let redirectLocation = "";
    if (isCurrentSearchLoaded && searchVersion && !isNewSearch) {
        if (searchVersion.status === SearchStatuses.Searching) {
            //TODO(1966): If coming from a quicksearch, should redirect to the quicksearches grid.
            redirectLocation = `${PathMap.searchRequests}`;
        }
        //If URL contains results
        else if (currentPath === PathMap.results.replaceAll("/", "")) {
            redirectLocation = resultsPageRedirectConditions(searchVersion) ?? redirectLocation;
        }
        //If URL ONLY contains search (without newversion or quick-search)
        else if (currentPath === PathMap.searchRequest.replaceAll("/", "") && !newVersion && !quickSearch) {
            redirectLocation = searchRequestPageRedirectConditions(searchVersion) ?? redirectLocation; //Change to redirect to correct search location based on ID
        }
        //If search is a quick search and URL doesn't contain
        else if (isCurrentSearchQuickSearch && !quickSearch && currentPath === PathMap.searchRequest.replaceAll("/", "")) {
            redirectLocation = `${PathMap.searchRequest}${searchVersion.searchId}${PathMap.quickSearch}`;
        }
        //If URL contains newversion
        else if (newVersion) {
            redirectLocation = newVersionRedirectConditions(searchVersion, user) ?? redirectLocation;
        }
        //If URL contains quick-search
        else if (quickSearch) {
            redirectLocation = quickSearchRedirectConditions(searchVersion) ?? redirectLocation;
        }
    }

    return (
        <Route
            path={routeProps.path}
            render={() => [
                <Backdrop style={conflictsStyles.backdropStyling} open={isSearching || isFetchingCurrentSearch}>
                    <CircularProgress color="inherit" />
                </Backdrop>,
                (!isFetchingCurrentSearch && isCurrentSearchLoaded && !redirectLocation) || isNewSearch ? (
                    routeProps.render()
                ) : !isFetchingCurrentSearch && isCurrentSearchLoaded && redirectLocation ? (
                    <Redirect to={redirectLocation} exact />
                ) : (
                    !isFetchingCurrentSearch &&
                    !isCurrentSearchLoaded &&
                    showNotFound && (
                        <PageContent>
                            <div>{"The search cannot be found."}</div>
                        </PageContent>
                    )
                )
            ]}
        />
    );
}
