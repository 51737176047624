import { Logger } from "aderant-web-fw-core";
import { Endpoint } from "./APIs/Endpoint";
import { AppInsightsClient } from "aderant-web-fw-azfunctions";
import { BlobStorageSecrets, CognitiveSearchSecrets, CosmosSecrets, DataFactorySecrets, RLSStorageConnectionInfo } from "./ConnectionDetails";
import { ConflictsAction } from "./Permissions";
import { LoggedInUser, LoggedInUserId } from "./User";
import { Direct, NonEmptyArray } from "./UtilityTypes";
import { unexpectedError } from "./Validation/Errors";

type KeyAuthableFunctionApps = "EntityStoreApi" | "SearchStoreApi" | "SearchApi" | "MonitoringApi" | "AdminApi";

//make sure this is &-ed onto every other sub context type for ergonomics - you should always have access to a logger no matter what.

export type LogContext = {
    readonly logger: Logger;
    readonly appInsightsClient: AppInsightsClient;
};

//We construct the whole Context type from a few narrower interfaces to allow for code to be written to those specifically.
//This way consumers can communicate clearly which parts of the context they need, allowing for less coupling and easier unit testing,
//while still allowing the simplicity and ease of refactoring from just passing the ConflictsContext through every callstack.

export type PermissionsContextService<Action extends ConflictsAction = ConflictsAction> = LogContext & {
    readonly currentUserId: string; //full user object intentionally not exposed on this type as code should not be manually checking roles etc. Id is still needed sometimes for comparing against e.g. assignedTo.
    currentUserHasPermission(action: Action): Promise<boolean>;
    currentUserHasAllPermissions(actions: NonEmptyArray<Action>): Promise<boolean>;
    currentUserHasAnyPermission(actions: NonEmptyArray<Action>): Promise<NonEmptyArray<Action> | false>;
    currentUserIsAderantUser(): Promise<boolean>;
};

export type PermissionsContextDirect = Direct<PermissionsContextService>;

export type PermissionsContext = PermissionsContextService | PermissionsContextDirect;

//**Please don't turn this into a god object** - only things that (roughly) *everything* needs access to should live on the Context
//      If specific functionality can live in a different class/function from this and just be seeded from the context state (e.g. tenancyName, currentUserId etc)
//      it probably should be. Currently Permissions (for client and backend) and Secret access (backend only) have passed the 'should it be here' test
//      because use of them is fairly ubiquitous, and especially for Permission access we want to reduce friction for doing things the Right Way™.

export type ConnectionContext = LogContext & {
    currentUser: Pick<LoggedInUserId, "tenancy">;
    getEntityStoreCosmosSecrets(): Promise<CosmosSecrets>;
    getSearchStoreCosmosSecrets(): Promise<CosmosSecrets>;
    getCognitiveSearchSecrets(): Promise<CognitiveSearchSecrets>;
    getDataFactorySecrets(): Promise<DataFactorySecrets>;
    getBlobStorageSecrets(): Promise<BlobStorageSecrets>;
    getRLSStorageConnectionInfo(): Promise<RLSStorageConnectionInfo>;
    getSharedBlobStorageConnectionString(): Promise<string>;
    getSharedFunctionHostKey(endpoint: Extract<Endpoint, KeyAuthableFunctionApps>): Promise<string>;
};

/**
 * Context for when you have no user given, but need access to secrets.  Must provide a tenancyName yourself
 */
export type UserlessConnectionContext = LogContext & {
    getEntityStoreCosmosSecrets(tenancyName: string): Promise<CosmosSecrets>;
    getSearchStoreCosmosSecrets(tenancyName: string): Promise<CosmosSecrets>;
    getCognitiveSearchSecrets(tenancyName: string): Promise<CognitiveSearchSecrets>;
    getDataFactorySecrets(tenancyName: string): Promise<DataFactorySecrets>;
    getBlobStorageSecrets(tenancyName: string): Promise<BlobStorageSecrets>;
    getSharedBlobStorageConnectionString(): Promise<string>;
    getSharedFunctionHostKey(endpoint: Extract<Endpoint, KeyAuthableFunctionApps>): Promise<string>;
};

export type AuthContext = InternalAuthContext | ExternalAuthContext;
export type InternalAuthContext = LogContext & {
    isExposedToEndUsers: false;
};
export type ExternalAuthContext = LogContext & {
    isExposedToEndUsers: true;
};

//We have a few different levels of user information we might know here. These are split
//into separate types tied to contexts rather than being optional values on one type so
//that we can avoid having to write a bunch of checks in business logic that can't be
//called with the less complete user information.

/** Full current user info, including email, roles, tenancy, subscription, etc. */
export type CurrentUserContext = LogContext & {
    readonly currentUser: LoggedInUser;
};

/** Constrained current user context for when we've been called from somewhere that doesn't
 * have a full logged in user, but does know the callers id. (Key Auth functions, Queue Triggered functions).
 */
export type BasicCurrentUserContext = LogContext & {
    readonly currentUser: LoggedInUserId;
};

/**
 * Returns true if the context has a tenancy that includes the subscription the user is logged in to.
 * @param context The context to check.
 */
export function isSubscriptionContext(context: BasicCurrentUserContext | CurrentUserContext): context is CurrentUserContext {
    //This is a type guard. We need to cast to test the type
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return !!(context as CurrentUserContext)?.currentUser.tenancy.subscription;
}

export type FunctionAppContextModel = {
    readonly environmentType: "AzureFunctionApp";
} & LogContext &
    CurrentUserContext &
    PermissionsContextService &
    ConnectionContext &
    ExternalAuthContext;

export type QueueTriggerFunctionAppContextModel = {
    readonly environmentType: "QueueTriggerFunction";
} & LogContext &
    BasicCurrentUserContext &
    ConnectionContext &
    InternalAuthContext;

export type KeyAuthFunctionAppContextModel = {
    readonly environmentType: "KeyAuthFunction";
} & LogContext &
    BasicCurrentUserContext &
    ConnectionContext &
    InternalAuthContext;

export type PublicKeyAuthFunctionAppContextModel = {
    readonly environmentType: "PublicKeyAuthFunction";
} & LogContext &
    CurrentUserContext &
    PermissionsContextService &
    ConnectionContext &
    ExternalAuthContext;

export type UserlessKeyAuthFunctionAppContextModel = {
    readonly environmentType: "UserlessKeyAuthFunction";
} & LogContext &
    UserlessConnectionContext &
    InternalAuthContext;

export type ClientAppContextModel = {
    readonly environmentType: "ClientApp";
} & LogContext &
    CurrentUserContext &
    PermissionsContextDirect;

export type KeyAuthFunctionInput<T> = {
    requestingUserId: string;
    requestingUniqueTenancyName: string;
    input: T;
};

export function isKeyAuthFunctionInput(value: unknown): value is KeyAuthFunctionInput<unknown> {
    // justification: need to to write type guard
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const maybe = value as KeyAuthFunctionInput<unknown>;
    return maybe !== null && typeof maybe === "object" && typeof maybe.requestingUniqueTenancyName === "string" && typeof maybe.requestingUserId === "string";
}

export type QueueMessage<T> = {
    id: string;
    operationId: string;
    operationParentId?: string;
    requestingUserId: string;
    requestingUniqueTenancyName: string;
    input: T;
};

export function validateQueueMessage(input: unknown, inputBindingName: string, logger: Logger): QueueMessage<unknown> {
    if (!input) {
        logger.debug("Queue message content is falsy.");
        throw unexpectedError(`Binding with name ${inputBindingName} was not found in queue message`, "validateQueueMessage");
    }

    // justification: type check function on unknown - have to do it
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const value = input as QueueMessage<unknown>;
    if (typeof value.id === "string" && typeof value.requestingUserId === "string" && typeof value.requestingUniqueTenancyName === "string") {
        return value;
    } else {
        logger.debug(
            "The id, requestingUserId and/or requestingUniqueTenancyName do not have the expected type. Types received = ",
            "typeof value.id: ",
            typeof value.id,
            ", typeof value.requestingUserId: ",
            typeof value.requestingUserId,
            ", typeof value.requestingUniqueTenancyName: ",
            typeof value.requestingUniqueTenancyName
        );
        //this situation really should be a 400 instead of a 500, but type checking with both ends being typescript should stop this from being called like this anyway.
        //todo: add a builtin type validation error that doesn't show up on the typescript contract for every api for situations like this.
        //see WI 2692
        throw unexpectedError("Expected message to contain basic queue message context values (requestingUniqueTenancyName & requestingUserId)", "validateQueueMessage");
    }
}
