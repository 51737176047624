import { IDENTITY_CONFLICTS_PRODUCTION_SUFFIX } from "aderant-conflicts-models";
import { UserResponse } from "../APIs/UserManagement/Surface";
import { AzureFunctionProxy } from "../AzureFunctionDefinition/shared/proxy";

export type TenancyUsers = {
    id: string;
    users: UserResponse[];
};

export function getUserManagementMock(tenancyUsers: TenancyUsers[]) {
    const userManagement: {
        tenancyUsers: TenancyUsers[];
        getUsers: AzureFunctionProxy<
            {
                tenancyId: string;
                subscriptionId: string;
            },
            UserResponse[],
            never
        >;
    } = {
        tenancyUsers: tenancyUsers,
        getUsers: async (param: { tenancyId: string; subscriptionId: string }) => {
            const tenancy = tenancyUsers.filter((tenancy) => {
                return tenancy.id == param.tenancyId;
            });
            if (tenancy && tenancy.length > 0) {
                if (param.subscriptionId !== IDENTITY_CONFLICTS_PRODUCTION_SUFFIX && param.subscriptionId.split(".")[1] !== IDENTITY_CONFLICTS_PRODUCTION_SUFFIX) {
                    return Promise.resolve(
                        tenancy[0].users.filter((user) =>
                            user.userApplications
                                ?.find((a) => a.applicationName.toLowerCase().startsWith("conflicts"))
                                ?.environments?.find((e) => e.environmentName === param.subscriptionId.split(".")[1])
                        )
                    );
                }
                return Promise.resolve(tenancy[0].users);
            }
            return Promise.resolve([]);
        }
    };
    return userManagement;
}
