import { EmailData } from "@sendgrid/helpers/classes/email-address";
import { EmailErrors, EmailWithContext, IDENTITY_CONFLICTS_PRODUCTION_SUFFIX, isNotNullOrUndefined, ok, Result, User } from "aderant-conflicts-models";
import * as Bluebird from "bluebird";
import * as uuid from "uuid";
import { APIs } from "../..";
import { InvalidQueueMessage } from "../AzureFunctionDefinition/AzureQueueErrors";
import { FunctionAppContext } from "../ConflictsContext";
import { GlobalUserService } from "../GlobalUserService/GlobalUserService";

export class EmailProvider {
    private globalUserService: GlobalUserService | undefined;
    private context: FunctionAppContext;
    private invalidEmails: EmailErrors.EmailValidationErrors;
    private users: User[] = [];
    private enqueueEmailMessage: (message: EmailWithContext) => Promise<Result<void, InvalidQueueMessage>>;

    constructor(context: FunctionAppContext, enqueueEmailMessage: (message: EmailWithContext) => Promise<Result<void, InvalidQueueMessage>>, globalUserService?: GlobalUserService) {
        this.context = context;
        this.globalUserService = globalUserService;
        this.enqueueEmailMessage = enqueueEmailMessage;
        this.invalidEmails = EmailErrors.invalidEmails();
    }

    async enqueueEmail(emailMessages: EmailWithContext[]): Promise<void> {
        if (emailMessages.length == 0) {
            return;
        }

        if (!this.globalUserService) {
            this.globalUserService = new GlobalUserService(this.context, APIs.getUserManagementProxy(this.context, this.context.logger));
        }
        //cache the users before creating emails, so concurrent creations don't all call user management
        this.users = await this.globalUserService.getUsers({
            tenancyId: this.context.currentUser.tenancy.id,
            uniqueName: this.context.currentUser.tenancy.uniqueName,
            subscriptionId: this.context.currentUser.tenancy.subscription?.id ?? IDENTITY_CONFLICTS_PRODUCTION_SUFFIX
        });

        this.invalidEmails = EmailErrors.invalidEmails();

        const createdEmails = emailMessages.map(this.createEmail);
        //filter out the emails that are null
        const actualEmails: EmailWithContext[] = createdEmails.filter(isNotNullOrUndefined);

        const response = await Bluebird.Promise.map(actualEmails, await this.enqueueEmailMessage, { concurrency: 16 }); //throttle number of items put on queue concurrently
        response.forEach((r) => {
            if (!ok(r)) {
                this.invalidEmails.errors.push(r);
            }
        });
        if (this.invalidEmails.errors.length > 0) {
            this.context.logger.error(this.invalidEmails.message, this.invalidEmails.errors);
        }
        this.context.logger.info(`enqueue email(s) done.`);
    }

    private createEmail = (emailMessage: EmailWithContext): EmailWithContext | null => {
        if (!(emailMessage && emailMessage.email.personalizations && emailMessage.email.personalizations[0] && emailMessage.email.personalizations[0].to)) {
            this.invalidEmails.errors.push(EmailErrors.noToEmailAddress(emailMessage.context));
            return null;
        }

        const emailTo = this.getEmailAddress(emailMessage.email.personalizations[0].to);
        if (!emailTo) {
            this.invalidEmails.errors.push(EmailErrors.toEmailAddressNotFound(emailMessage.context));
            return null;
        }
        if (Array.isArray(emailTo)) {
            const validToEmails = emailTo.filter((t: EmailData) => {
                return typeof t == "object" && t.email;
            });
            if (emailTo.length > validToEmails.length) {
                this.invalidEmails.errors.push(EmailErrors.toEmailAddressNotFound(emailMessage.context));
            }
            emailMessage.email.personalizations[0].to = validToEmails;
        } else {
            emailMessage.email.personalizations[0].to = emailTo;
        }

        //Add the product name (Conflicts) and the unique tenancy name as categories, so undelivered emails can be filtered by product or tenancy in SendGrid reports
        emailMessage.email.categories = ["Conflicts", this.context.currentUser.tenancy.uniqueName];
        return emailMessage;
    };

    private getUser = (id: string): User | null => {
        this.context.logger.debug(`getUser for id: ${JSON.stringify(id, null, 2)}`);
        const user = this.users.find((x) => x.id == id);
        return user ?? null;
    };

    private convertToEmailAddress = (address: EmailData): EmailData => {
        if (typeof address === "string" && uuid.validate(address)) {
            //we have a guid. This must be the user id - fetch the user's email address
            this.context.logger.debug(`convertToEmailAddress for guid: ${JSON.stringify(address, null, 2)}`);
            const user = this.getUser(address);
            this.context.logger.debug(`convertToEmailAddress retrieved user: ${JSON.stringify(user, null, 2)}`);
            if (user && user.email) {
                return {
                    name: user.name ?? "",
                    email: user.email
                };
            }
            this.context.logger.warn(`convertToEmailAddress did not retrieve a valid user with email: returns empty string`);
            return "";
        }
        if (!address) {
            this.context.logger.warn(`convertToEmailAddress received empty address: returns empty string`);
            return "";
        }
        if (typeof address == "object" && !address.email) {
            this.context.logger.warn(`convertToEmailAddress received address with empty email: returns empty string`);
            return "";
        }
        this.context.logger.debug(`convertToEmailAddress for valid address: return unchanged: ${JSON.stringify(address, null, 2)}`);
        return address;
    };

    private getEmailAddress = (emailTo: EmailData | EmailData[]): EmailData | EmailData[] => {
        if (Array.isArray(emailTo)) {
            this.context.logger.debug(`getEmailAddress for multiple to emails: ${JSON.stringify(emailTo, null, 2)}`);
            const addresses = emailTo.map((address: EmailData) => {
                return this.convertToEmailAddress(address);
            });
            return addresses;
        } else {
            this.context.logger.debug(`getEmailAddress for single to email: ${JSON.stringify(emailTo, null, 2)}`);
            return this.convertToEmailAddress(emailTo);
        }
    };
}
