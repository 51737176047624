import { CompositeApiKey, CurrentUserContext, IDENTITY_CONFLICTS_PRODUCTION_SUFFIX, StoredHashedApiKey, getSubscriptionName } from "aderant-conflicts-models";
import { hash } from "./hash";
import { randomUUID } from "crypto";

export function generateKey(context: CurrentUserContext, uniqueKeyName: string): { encodedCompositeKey: string; keyToStore: StoredHashedApiKey } {
    const newKey = randomUUID();
    let keyToStore: StoredHashedApiKey;
    if (
        context.currentUser.tenancy.subscription?.id &&
        getSubscriptionName(context.currentUser.tenancy.uniqueName, context.currentUser.tenancy.subscription?.id, "generateKey") !== IDENTITY_CONFLICTS_PRODUCTION_SUFFIX
    ) {
        //Store key for non-production subscriptions
        keyToStore = {
            uniqueKeyName: uniqueKeyName,
            hashedKey: hash(newKey),
            uniqueTenantName: context.currentUser.tenancy.uniqueName,
            //use of "deprecated" field - deprecated to make sure it's not used for anything other than calling user management fine here because that's what it's needed for.
            tenantId: context.currentUser.tenancy.id,
            subscriptionId: context.currentUser.tenancy.subscription.id
        };
    } else {
        //Store key for production subscriptions
        keyToStore = {
            uniqueKeyName: uniqueKeyName,
            hashedKey: hash(newKey),
            uniqueTenantName: context.currentUser.tenancy.uniqueName,
            //use of "deprecated" field - deprecated to make sure it's not used for anything other than calling user management fine here because that's what it's needed for.
            tenantId: context.currentUser.tenancy.id
        };
    }

    const compositeKey: CompositeApiKey = `${uniqueKeyName}:${newKey}:${context.currentUser.tenancy.uniqueName}`;
    const encodedCompositeKey = Buffer.from(compositeKey).toString("base64");

    return { encodedCompositeKey, keyToStore };
}
