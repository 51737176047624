import AddIcon from "@mui/icons-material/Add";
import { getSearchSummariesIds, SearchStatuses, SearchSummary, SearchVersionIdentifier, User } from "aderant-conflicts-models";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button, DataGrid, DataGridColumnDefinition, DataGridPreference, getColumnPreferenceByColumnId, FilterMenu, FilterMenuItem } from "@aderant/aderant-react-components";
import PageContent from "components/PageContainer/PageContent";
import { usePolling } from "hooks/usePolling";
import { RootState } from "MyTypes";
import React, { CSSProperties, useCallback, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { appActions, searchActions } from "state/actions";
import { GridIds } from "state/reducers/appReducers";
import { getAllQuickSearchSummaries, getAllSearchingQuickSearchSummaries, getAllUsers } from "state/selectors";
import uuid from "uuid";
import { getGridPreferences, getIsQuickSearchEnabled, getSelectedSubscription } from "../../state/selectors/appSelectors";
import { SearchColumnDefinitions } from "./SearchColumnDefinitions";
import { createSearchRequestRows, SearchRequestRow } from "./SearchRequestRow";
import { getShowHideMenuOptions, gridPreferencesEqualityFunction } from "components/DataGridWithRefiner/DataGridWithRefiner";
import { Messages as DataGridWithRefinerMessages } from "../../components/DataGridWithRefiner/Messages";
import { Messages } from "./Messages";
import { OmitStrict } from "aderant-web-fw-core";
import _ from "lodash";
import { conflictsPalette } from "styles/conflictsPalette";

export default function QuickSearchesPage() {
    const allUsers: User[] | undefined = useSelector(getAllUsers);
    const searchSummaries: SearchSummary[] = useSelector(getAllQuickSearchSummaries, shallowEqual);
    const searchingSearchSummaries: SearchSummary[] = useSelector(getAllSearchingQuickSearchSummaries, shallowEqual);
    const searchSummariesAreLoaded: boolean = useSelector((rootState: RootState) => rootState.search.areSearchSummariesLoaded);
    const searchIdsCurrentlyProcessing: string[] = useSelector((rootState: RootState) => rootState.search.searchIdsCurrentlyProcessing);
    //ToDo: selectedSearches is inferred as any[]. Need to investigate how to make it strongly typed.
    const [selectedSearches, setSelectedSearches] = useState<SearchRequestRow[]>([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const subscriptionId = useSelector(getSelectedSubscription)?.id;
    const gridId = GridIds.quickSearchesPage;
    const gridPreferences = useSelector(getGridPreferences(gridId), gridPreferencesEqualityFunction);
    const gridRef = useRef<HTMLDivElement>(null);
    const isQuickSearchEnabled = useSelector(getIsQuickSearchEnabled);

    if (!isQuickSearchEnabled) {
        history.push("/");
    }

    const searchRequestRows = useMemo(() => {
        if (searchSummaries.length > 0) {
            return createSearchRequestRows(searchSummaries, allUsers ?? []);
        }
        return [];
    }, [searchSummaries, allUsers?.length]);

    const pollingAction = useCallback((data) => {
        const searchingSearchSummariesIds = getSearchSummariesIds(data);
        dispatch(searchActions.fetchSearchSummariesById(searchingSearchSummariesIds));
    }, []);

    // Polling searching search summaries; fetching every 15 seconds for 15 minutes
    usePolling(searchingSearchSummaries, pollingAction, 15000, 60, searchingSearchSummaries.length > 0);

    const handleNewQuickSearch = () => {
        dispatch(searchActions.isNewSearch(true));
        history.push(`/search/${uuid()}/quick-search`);
    };

    const searchesColumns: readonly DataGridColumnDefinition<SearchRequestRow>[] = useMemo(
        () => [SearchColumnDefinitions.name, SearchColumnDefinitions.resultCount, SearchColumnDefinitions.createdOn, SearchColumnDefinitions.searchDate],
        []
    );

    const onShowHideSelectionChanged = (changedItem: OmitStrict<FilterMenuItem<string>, "checked"> & { checked: boolean }) => {
        const newGridPreferences = _.cloneDeep(gridPreferences);
        if (!newGridPreferences.columnPreferences) {
            newGridPreferences.columnPreferences = [];
        }
        const [columnPrefToUpdate, wasCreated] = getColumnPreferenceByColumnId(newGridPreferences.columnPreferences, changedItem.value);
        columnPrefToUpdate.hidden = !changedItem.checked;
        if (wasCreated) {
            newGridPreferences.columnPreferences.push(columnPrefToUpdate);
        }
        if (!subscriptionId) {
            console.error("Subscription id not found in app state. Unable to update grid preferences.");
        } else {
            dispatch(appActions.updateGridPreference({ preference: newGridPreferences, subscriptionId: subscriptionId }));
        }
    };

    const searchesPageToolbar: JSX.Element = (
        <div style={{ display: "flex", minHeight: "55px", alignItems: "center", justifyContent: "flex-end", width: "100%" }}>
            <Button text={Messages.NEW_QUICK_SEARCH_BUTTON.getMessage()} endIcon={<AddIcon />} onClick={handleNewQuickSearch} rounded={true} size="medium" style={{ flexShrink: 0, marginRight: 15 }} />

            <FilterMenu
                items={getShowHideMenuOptions(searchesColumns, gridPreferences?.columnPreferences ?? [])}
                iconButton
                aria-label={DataGridWithRefinerMessages.COLUMN_PREFERENCES.getMessage()}
                startIcon={<SettingsIcon />}
                rounded
                variant="outlined"
                container={gridRef.current}
                onSelectedChanged={onShowHideSelectionChanged}
            />
        </div>
    );

    const rowMetadata = {
        getIsRowReadOnly: useCallback((rowData: SearchRequestRow) => rowData.statusEnum === SearchStatuses.Searching, []),
        getIsRowLoading: useCallback((rowData: SearchRequestRow) => (rowData.id ? searchIdsCurrentlyProcessing.includes(rowData.id) : false), [searchIdsCurrentlyProcessing]),
        getRowId: useCallback((rowData: SearchRequestRow) => (rowData.id ? rowData.id.toString() : ""), [])
    };

    const gridContainerStyle: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        overflowY: "auto",
        height: "100%",
        backgroundColor: conflictsPalette.background.white
    };

    const deleteSearches = (selectedRows: SearchRequestRow[], event?: React.MouseEvent) => {
        if (event) {
            event.stopPropagation();
        }
        if (selectedRows) {
            const searchVersionIdentifiers = selectedRows
                .map((row) => {
                    if (row.id) {
                        return { searchId: row.id, versionId: row.versionId };
                    }
                })
                .filter((identifier): identifier is SearchVersionIdentifier => identifier !== undefined);
            searchVersionIdentifiers.length && dispatch(searchActions.deleteSearches({ searchVersionIdentifiers }));
        } else {
            console.error("Could not delete searches from grid, search ids are null");
        }
    };

    return (
        <PageContent style={{ margin: "30px 10%" }}>
            <div className="grid-container" style={gridContainerStyle} ref={gridRef}>
                <DataGrid<SearchRequestRow>
                    columnDefinitions={searchesColumns}
                    data={searchRequestRows}
                    toolbar={searchesPageToolbar}
                    options={{
                        allowSelection: true,
                        selectionActions: [selectedSearches.length > 0 && <Button text="Delete" key="grid-delete-button" onClick={() => deleteSearches(selectedSearches)} />],
                        onSelectedRowsChanged: setSelectedSearches
                    }}
                    style={{
                        rowHeight: 50
                    }}
                    onGridPreferencesChange={(newGridPreferences: DataGridPreference) => {
                        if (!subscriptionId) {
                            console.error("Subscription id not found in app state. Unable to update grid preferences.");
                        } else {
                            dispatch(appActions.updateGridPreference({ preference: newGridPreferences, subscriptionId: subscriptionId }));
                        }
                    }}
                    gridPreferences={gridPreferences}
                    dataIsLoading={!searchSummariesAreLoaded}
                    rowMetadata={rowMetadata}
                />
            </div>
        </PageContent>
    );
}
