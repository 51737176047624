import { Forbidden, GridConfiguration, IDENTITY_MAIN_ENVIRONMENT_NAME, IDENTITYDEV_MAIN_ENVIRONMENT_NAME, Lookup, NotFound, ok, Result } from "aderant-conflicts-models";
import { DataGridPreference, DataGridPreferences, DataGridSubscriptionPreferences } from "@aderant/aderant-react-components";
import { put } from "redux-saga/effects";
import { appActions, appActionTypes } from "state/actions";
import * as SagaContext from "../store/ConflictsSagaContext";
import { Action, Return, watch } from "./common";

export const CONFLICTS_GRID_PREFERENCES_DEFAULT_KEY = "conflicts_gridPreferences";
export const CONFLICTS_GRID_PREFERENCES_SUBSCRIPTIONS_KEY = "conflicts_gridPreferences_bySubscription";

export const appWatchers = [
    function* fetchUsersWatcher() {
        yield* watch(appActionTypes.FETCH_USERS, fetchUsers);
    },

    function* fetchLookupsWatcher() {
        yield* watch(appActionTypes.FETCH_LOOKUPS, fetchLookups);
    },

    function* fetchHitResultGridConfigurationWatcher() {
        yield* watch(appActionTypes.FETCH_HIT_RESULT_GRID_CONFIGURATION, fetchHitResultGridConfiguration);
    },

    function* fetchGridPreferencesWatcher() {
        yield* watch(appActionTypes.FETCH_GRID_PREFERENCES, fetchGridPreferences);
    },

    function* updateGridPreferenceWatcher() {
        yield* watch(appActionTypes.UPDATE_GRID_PREFERENCE, updateGridPreference);
    }
];

function* fetchUsers() {
    const userService = yield* SagaContext.getUserService();
    // fetch the users that can use the application
    const users: Return<typeof userService.getUsers> = yield userService.getUsers();
    // dispatch the fetchUsers action
    yield put(appActions.fetchUsersSuccess(users));
}

/**
 * Retrieve lookups from the entity store.  Currently fetches affiliations and party statuses.
 *
 * Future tasks:
 *  - Refactor so that there is one lookup fetch method that this saga calls twice.  Not done atm because I haven't figured out how to do this
 *    with a generator function
 *  - These messages are user visible, should be exposed for translation
 */
function* fetchLookups() {
    const logger = yield* SagaContext.getLogger();
    const entityStorageService = yield* SagaContext.getEntityStorageService();
    try {
        let affiliationList: Lookup[] = [];
        let partyStatusList: Lookup[] = [];
        const affiliationResponse: Result<Lookup[], NotFound> = yield entityStorageService.getLookup({ lookupType: "Affiliation" });
        if (ok(affiliationResponse)) {
            affiliationList = affiliationResponse;
            if (affiliationList.length === 0) {
                yield put(appActions.actionFailure(`No Affiliations were found`));
            }
        } else {
            yield put(appActions.actionFailure(`Unable to fetch Affiliation list: ${affiliationResponse.message}`));
        }
        const partyStatusResponse: Result<Lookup[], NotFound> = yield entityStorageService.getLookup({ lookupType: "PartyStatus" });
        if (ok(partyStatusResponse)) {
            partyStatusList = partyStatusResponse;
            if (partyStatusList.length === 0) {
                yield put(appActions.actionFailure(`No Party Statuses were found`));
            }
        } else {
            yield put(appActions.actionFailure(`Unable to fetch Party Status list: ${partyStatusResponse.message}`));
        }
        yield put(appActions.fetchLookupsSuccess({ affiliationList: affiliationList, partyStatusList: partyStatusList }));
    } catch (error) {
        logger.error("Unable to fetch lookups", error);
        yield put(appActions.actionFailure(`Unable to fetch Lookups: ${error}`));
    }
}

/**
 * Retrieve the hit result grid configuration from the Admin API.
 *
 * Future tasks:
 *  - These messages are user visible, should be exposed for translation
 */
function* fetchHitResultGridConfiguration() {
    const logger = yield* SagaContext.getLogger();
    const adminService = yield* SagaContext.getAdminService();
    try {
        const getHitResultGridConfigurationResponse: Result<GridConfiguration, NotFound | Forbidden> = yield adminService.getHitResultGridConfiguration();
        if (ok(getHitResultGridConfigurationResponse)) {
            yield put(appActions.fetchHitResultGridConfigurationSuccess(getHitResultGridConfigurationResponse));
        } else {
            yield put(appActions.actionFailure(`Unable to fetch Hit Result grid configuration: ${getHitResultGridConfigurationResponse.message}`));
        }
    } catch (error) {
        logger.error("Unable to fetch Hit Result grid configuration.", error);
        yield put(appActions.actionFailure(`Unable to fetch Hit Result grid configuration: ${error}.`));
    }
}

/**
 * Returns the default grid preferences.
 * The default preferences are the ones saved for the PROD environment, before multi-subscription was implemented
 */
export function getDefaultGridPreferencesFromLocalStorage(): DataGridPreferences {
    const defaultPreferencesString = localStorage.getItem(CONFLICTS_GRID_PREFERENCES_DEFAULT_KEY);
    try {
        const defaultPreferences = defaultPreferencesString ? JSON.parse(defaultPreferencesString) : {};
        return defaultPreferences;
    } catch {
        console.log(`Error parsing grid preferences with key ${CONFLICTS_GRID_PREFERENCES_DEFAULT_KEY} from local storage`);
        return {};
    }
}

/**
 * Returns grid preferences for all subscriptions, but sets the preferences for the requested subscription from the default preferences, if it doesn't exist
 * The default preferences are the ones saved for the PROD environment, before multi-subscription was implemented
 */
export function getGridPreferencesFromLocalStorage(subscriptionId: string): DataGridSubscriptionPreferences {
    const defaultPreferences = getDefaultGridPreferencesFromLocalStorage();
    const subscriptionPreferencesString = localStorage.getItem(CONFLICTS_GRID_PREFERENCES_SUBSCRIPTIONS_KEY);
    let subscriptionPreferences: DataGridSubscriptionPreferences = {};
    if (subscriptionPreferencesString) {
        try {
            subscriptionPreferences = JSON.parse(subscriptionPreferencesString);
        } catch {
            console.log(`Error parsing grid preferences with key ${CONFLICTS_GRID_PREFERENCES_SUBSCRIPTIONS_KEY} from local storage`);
        }
    }
    if (!subscriptionPreferences[subscriptionId]) {
        if (subscriptionId === IDENTITY_MAIN_ENVIRONMENT_NAME || subscriptionId === IDENTITYDEV_MAIN_ENVIRONMENT_NAME) {
            subscriptionPreferences[subscriptionId] = defaultPreferences;
        } else {
            subscriptionPreferences[subscriptionId] = {};
        }
    }
    return subscriptionPreferences;
}

function* fetchGridPreferences(action: Action<typeof appActionTypes.FETCH_GRID_PREFERENCES, string>) {
    //Fetch the grid preferences from local storage and pass only the preferences for the requested subscription to the store
    //pauload is the subscriptionId
    yield put(appActions.fetchGridPreferencesSuccess(action.payload ? getGridPreferencesFromLocalStorage(action.payload)[action.payload] : getDefaultGridPreferencesFromLocalStorage()));
}

export function updateGridPreferenceToLocalStorage(payload: { preference: DataGridPreference; subscriptionId: string }): void {
    if (payload.subscriptionId) {
        //This code is intended for running in the multi-subscription environment.
        //When running with multi-subscription we should always have a subscriptionId
        //Fetch the grid preferences for all subscriptions
        const preferences = getGridPreferencesFromLocalStorage(payload.subscriptionId);
        //Then update the grid preference for the given subscription
        preferences[payload.subscriptionId][payload.preference.id] = payload.preference;
        //And save the updated preferences back to local storage
        localStorage.setItem(CONFLICTS_GRID_PREFERENCES_SUBSCRIPTIONS_KEY, JSON.stringify(preferences));
    } else {
        throw new Error("SubscriptionId is required to update grid preferences");
    }
}

function* updateGridPreference(action: Action<typeof appActionTypes.UPDATE_GRID_PREFERENCE, { preference: DataGridPreference; subscriptionId: string }>) {
    //Update the grid preference to local storage
    updateGridPreferenceToLocalStorage(action.payload);
    //Update the grid preference in the store
    yield put(appActions.updateGridPreferenceSuccess(action.payload.preference));
}
